import { Grade } from "db"

let contentGroups = {
  contentGroup0: undefined,
  contentGroup1: undefined,
  contentGroup2: undefined,
  contentGroup3: undefined,
  contentGroup4: undefined,
  contentGroup5: undefined,
  contentGroup6: undefined,
  contentGroup7: undefined,
  contentGroup8: undefined,
} as const

// Define the type of the contentGroups
export type ContentGroupsObject = {
  [K in keyof typeof contentGroups]: boolean | undefined
} & {
  contentGroupLabel: string
}

export default function getAnalyticsContentGroups<ContentGroupsObject>(
  grades: Grade[]
) {
  let contentContentGroups = {}

  if (grades.length === 0) {
    return contentGroups
  }

  grades.forEach((grade) => {
    const groupKey = `contentGroup${grade.number}`
    contentContentGroups[groupKey] = true
  })

  for (const cG in contentGroups) {
    if (!contentContentGroups[cG]) {
      contentContentGroups[cG] = false
    }
  }

  return contentContentGroups
}
