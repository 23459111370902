import { UserListAccessLevel, OrganizationUserRole } from "db"


export default function getListTypeCanAdd(accessLevel: UserListAccessLevel, role?: OrganizationUserRole) {
  switch (accessLevel) {
    case "PERSONAL":
    case "GROUP":
    case "KIDS":
      return true
    case "GLOBAL":
      return false
    case "SCHOOL":
      if (role === "MANAGER" || role === "INITIATOR") {
        return true
      }
      return false
  }
}