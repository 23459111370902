import { useMutation, useQuery } from "@blitzjs/rpc"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaSlidersH } from "@react-icons/all-files/fa/FaSlidersH"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { TbPlus } from "@react-icons/all-files/tb/TbPlus"
import { Value } from "@stringtale/react"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import { MAX_KIDS_LIST } from "app/kids/consts"
import useToast from "app/toasts/hooks/useToast"
import { Group as TGroup, UserListAccessLevel, UserListType } from "db"
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react"
import {
  ADD_PLAYLIST_BLUE_COLOR,
  BORDER_GREY_COLOR,
  DARK_BLUE_COLOR,
  DARK_ORANGE_COLOR,
  GREEN_COLOR,
  LIGHT_GREY_COLOR,
  LIGHT_ORANGE_COLOR,
  MODAL_OVERLAY_Z_INDEX,
  ORANGE_COLOR,
  RED_COLOR,
} from "theme/consts"
import { PX12, PX14, PX16 } from "theme/sizes"
import CheckboxBase from "ui/Checkbox"
import Group from "ui/Group"
import HrBase from "ui/Hr"
import {
  CancelButton,
  Close,
  Content as ContentBase,
  Overlay as OverlayBase,
  Root as RootBase,
  ScrollArea as ScrollAreaBase,
  SubmitButton,
  Title,
} from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import TextButton from "ui/TextButton"
import getDataFromFormData from "utils/form/getDataFromFormData"
import { ADD_TO_PLAYLIST_TYPES } from "../consts"
import addLessonToLists from "../mutations/addLessonToLists"
import getAddableLists, {
  GetAddableListsResult,
} from "../queries/getAddableLists"
import getListTypeDisplayTitle from "../utils/getListTypeDisplayTitle"
import getListsFilteredOnGrades from "../utils/getListsFilteredOnGrades"
import PlaylistMenuCircleBase from "./PlaylistMenuCircle"

export const PersonalItem = Symbol("personal")

type AddToPlaylistLesson = {
  id: number
  displayTitle: string | null
}

type AddToPlaylistProps = {
  onClose: () => void
  setSubmittableToKidsListWarningOpen: (val: boolean) => void
  lessons: AddToPlaylistLesson[]
  setIsNewPlaylistOpen: Dispatch<SetStateAction<boolean>>
  currentGrades: number[]
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  value: string[]
  setValue: Dispatch<SetStateAction<string[]>>
}

const Root = styled(RootBase)`
  font-size: ${PX14};
  flex: 1;
  z-index: ${MODAL_OVERLAY_Z_INDEX - 1};
`

const Overlay = styled(OverlayBase)`
  z-index: ${MODAL_OVERLAY_Z_INDEX - 2};
`

const Content = styled(ContentBase)`
  flex-direction: column;
  padding: 32px;
`

const Hr = styled(HrBase)`
  color: ${BORDER_GREY_COLOR};
`

const Label = styled("label")`
  width: 100%;
  padding: 0 0 0 16px;
  box-sizing: border-box;
`

const ItemRoot = styled("div")<{ disabled?: boolean; checked?: boolean }>`
  display: grid;
  grid-template-columns: 19px auto;
  background: white;
  align-items: center;
  height: 46px;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 16px;
  ${({ checked }) =>
    checked &&
    css`
      background-color: ${LIGHT_ORANGE_COLOR};
    `}
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
          ${Label} {
            cursor: default;
          }
          ${Checkbox} {
            cursor: default;
          }
        `
      : css`
          color: inherit;
          ${Label} {
            cursor: pointer;
          }
          ${Checkbox} {
            cursor: pointer;
          }
        `};
`

const ItemList = styled(Stack)`
  border: 1px solid #b2b1c9;
  border-radius: 8px;
  overflow: hidden;
  height: 472px;
`

const Checkbox = styled(CheckboxBase)`
  cursor: pointer;
`

const ScrollArea = styled(ScrollAreaBase)`
  padding: 8px;
`

const getItemKey = ({
  isBulletin,
  accessLevel,
}: {
  isBulletin: boolean
  accessLevel: UserListAccessLevel | ""
}) => {
  if (isBulletin) {
    return "bulletinBoards"
  } else if (accessLevel === "KIDS") {
    return "kidsPlaylists"
  } else if (accessLevel === "GROUP" || accessLevel === "PERSONAL") {
    return "groupPlaylists"
  } else if (accessLevel === "SCHOOL") {
    return "schoolPlaylists"
  }
  return "playlists"
}

const ItemListSection = ({ title, children }) => {
  return (
    <Stack gap="8px">
      <Group
        justify="center"
        align="center"
        gap="8px"
        style={{ padding: "0 0 0 8px" }}
      >
        <Text color={ADD_PLAYLIST_BLUE_COLOR} size={PX12}>
          {title}
        </Text>
        <Hr />
      </Group>
      <Stack gap="4px">{children}</Stack>
    </Stack>
  )
}

const StyledFilterButton = styled(RoundedButton)<{
  checked?: boolean
  variant?: "filled" | "bordered"
}>`
  border-radius: 8px;
  height: 46px;
  box-sizing: border-box;
  border: 1px solid ${LIGHT_GREY_COLOR};
  ${Text} {
    font-weight: 500;
  }

  &:hover {
    background-color: ${({ variant }) =>
      variant === "filled" ? DARK_ORANGE_COLOR : LIGHT_ORANGE_COLOR};
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${LIGHT_ORANGE_COLOR};
      border-color: ${ORANGE_COLOR};
      color: ${ORANGE_COLOR};
      ${Text} {
        font-weight: 600;
      }
      &:hover {
        border-color: ${RED_COLOR};
        color: ${RED_COLOR};
      }
    `}
`

const LabelContent = styled("div")`
  display: grid;
  grid-template-columns: 32px auto;
  gap: 8px;
  justify-content: center;
  align-items: center;
`

const PlaylistMenuCircle = styled(PlaylistMenuCircleBase)`
  height: 32px;
  width: 32px;
`

const FilterButton = ({
  value,
  filters,
  setFilters,
  children,
}: {
  value: ADD_TO_PLAYLIST_TYPES
  filters: ADD_TO_PLAYLIST_TYPES[]
  setFilters: Dispatch<SetStateAction<ADD_TO_PLAYLIST_TYPES[]>>
  children: ReactNode
}) => {
  return (
    <StyledFilterButton
      color="blue"
      variant={"bordered"}
      checked={filters.includes(value)}
      onClick={() =>
        filters.includes(value)
          ? setFilters((prevState) =>
              prevState.filter((filter) => filter !== value)
            )
          : setFilters((prevState) => [...prevState, value])
      }
    >
      <Text>{children}</Text>
    </StyledFilterButton>
  )
}
const Item = ({
  id,
  displayTitle,
  accessLevel,
  type,
  isBulletin,
  group,
  value,
  setValue,
  usedUserPlaylist,
  usedBulletinBoardGroups,
  playlist,
}: {
  id: number | typeof PersonalItem
  displayTitle?: string | null
  accessLevel?: UserListAccessLevel
  type?: UserListType
  isBulletin?: boolean
  group?: TGroup
  value: string[]
  setValue: Dispatch<SetStateAction<string[]>>
  usedUserPlaylist: {
    id: number | typeof PersonalItem
  }[]
  usedBulletinBoardGroups: { id: number | typeof PersonalItem }[]
  playlist?: NonNullable<GetAddableListsResult>["lists"]["groupLists"][number]["playlist"]
}) => {
  const maxKidsListReached = !!(
    accessLevel === "KIDS" &&
    playlist?.lessons &&
    playlist.lessons?.length >= MAX_KIDS_LIST
  )
  const disabled =
    (isBulletin && usedBulletinBoardGroups.some((group) => group.id === id)) ||
    (!isBulletin && usedUserPlaylist.some((playlist) => playlist.id === id)) ||
    maxKidsListReached ||
    (id === PersonalItem && usedBulletinBoardGroups?.length > 0)
  const currentValue =
    !isBulletin || id === PersonalItem
      ? id.toString()
      : `bulletin-${id.toString()}`
  return (
    <ItemRoot disabled={disabled} checked={value.includes(currentValue)}>
      <Checkbox
        size="large"
        id={currentValue}
        value={currentValue}
        checked={value.includes(currentValue)}
        onCheckedChange={() => {
          setValue((prevState) => {
            if (value.includes(currentValue)) {
              return prevState.filter((i) => i !== currentValue)
            }
            return [...prevState, currentValue]
          })
        }}
        name={`${getItemKey({ isBulletin: !!isBulletin, accessLevel: accessLevel || "" })}[]`}
        disabled={disabled}
      />
      <Label htmlFor={currentValue}>
        <Group justify="space-between" align="flex-start">
          <LabelContent>
            {isBulletin ? (
              <PlaylistMenuCircle
                color={ORANGE_COLOR}
                accessLevel={"KIDS"}
                type={"PLAYLIST"}
              />
            ) : playlist && accessLevel && type ? (
              <PlaylistMenuCircle
                color={playlist.color || GREEN_COLOR}
                accessLevel={accessLevel}
                type={type}
              />
            ) : null}
            <Stack grow align="flex-start">
              <Text bold>{displayTitle}</Text>
              {!isBulletin && (
                <Text color={ADD_PLAYLIST_BLUE_COLOR} size={PX12}>
                  {accessLevel && type && accessLevel === "GROUP" ? (
                    <>
                      {`${group?.displayTitle} • `}
                      {accessLevel &&
                        type &&
                        getListTypeDisplayTitle(accessLevel, type)}
                    </>
                  ) : accessLevel && type ? (
                    <>{getListTypeDisplayTitle(accessLevel, type)}</>
                  ) : null}
                </Text>
              )}
            </Stack>
          </LabelContent>
          {disabled && !maxKidsListReached ? (
            <Text color={DARK_BLUE_COLOR} size={PX12}>
              <Value name="apps.web.src.playlists.components.addlessontolistmodal.les_is_al_toegevoegd">
                Les is al toegevoegd
              </Value>
            </Text>
          ) : accessLevel === "KIDS" && playlist?.lessons ? (
            <Text
              color={
                playlist.lessons.length === MAX_KIDS_LIST
                  ? DARK_ORANGE_COLOR
                  : DARK_BLUE_COLOR
              }
            >{`${playlist.lessons.length} / ${MAX_KIDS_LIST}`}</Text>
          ) : null}
        </Group>
      </Label>
    </ItemRoot>
  )
}

const NewAddToPlaylistModal = ({
  lessons,
  onClose,
  setSubmittableToKidsListWarningOpen,
  setIsNewPlaylistOpen,
  currentGrades,
  setIsDrawerOpen,
  value,
  setValue,
}: AddToPlaylistProps) => {
  const addToast = useToast()
  const currentUser = useCurrentUser()
  const [addLessonToPlaylistMutation] = useMutation(addLessonToLists)
  const [userPlaylists] = useQuery(getAddableLists, undefined, {
    keepPreviousData: true,
  })

  const [filters, setFilters] = useState<ADD_TO_PLAYLIST_TYPES[]>([])

  const lessonIds = useMemo(() => lessons.map((l) => l.id), [lessons])

  const usedUserPlaylist = useMemo(() => {
    if (userPlaylists) {
      const groupLists = userPlaylists.lists.groupLists
        .filter(
          (groupPlaylist) =>
            // Check if any lesson ID in this groupPlaylist's playlist exists in the targetLessonIds array
            groupPlaylist.playlist &&
            groupPlaylist.playlist.lessons &&
            groupPlaylist.playlist.lessons.some((lesson) =>
              lessonIds.includes(lesson.lessonId)
            )
        )
        .map((item) => ({
          id: item.id,
        }))

      const schoolLists = userPlaylists.lists.schoolLists
        .filter(
          (item) =>
            item.playlist &&
            item.playlist.lessons &&
            item.playlist.lessons.some((lesson) =>
              lessonIds.includes(lesson.lessonId)
            )
        )
        .map((item) => ({
          id: item.id,
        }))

      const kidsLists = userPlaylists.lists.kidsPlaylists
        .filter(
          (item) =>
            item.playlist &&
            item.playlist.lessons &&
            item.playlist.lessons.some((lesson) =>
              lessonIds.includes(lesson.lessonId)
            )
        )
        .map((item) => ({
          id: item.id,
        }))

      return [...groupLists, ...schoolLists, ...kidsLists]
    }
    return []
  }, [userPlaylists, lessonIds])

  const usedBulletinBoardGroups = useMemo(() => {
    if (userPlaylists && userPlaylists.userGroups.length > 0) {
      const userGroups = userPlaylists.userGroups
        .filter((group) =>
          group.bulletinBoardItems.some(
            (item) => item.lessonId && lessonIds.includes(item.lessonId)
          )
        )
        .map((group) => ({
          id: group.id,
        }))
      return userGroups
    } else if (
      userPlaylists &&
      userPlaylists.bulletinBoardItems &&
      userPlaylists.userGroups.length === 0
    ) {
      const bBI = userPlaylists.bulletinBoardItems.find(
        (bBI) => bBI.lessonId && lessonIds.includes(bBI.lessonId)
      )
      if (bBI) {
        return [{ id: PersonalItem } as const]
      }
    }
    return []
  }, [userPlaylists, lessonIds])

  if (!userPlaylists) return null

  const { userGroups, lists } = userPlaylists
  const { groupLists, schoolLists, kidsPlaylists } = lists

  const filteredUserGroups =
    currentGrades.length < 1
      ? userGroups
      : userGroups.filter((userGroup) => currentGrades.includes(userGroup.id))

  const filteredGroupLists = getListsFilteredOnGrades({
    lists: groupLists,
    currentGrades,
  })
  const filteredSchoolLists = getListsFilteredOnGrades({
    lists: schoolLists,
    currentGrades,
  })
  const filteredKidsLists = getListsFilteredOnGrades({
    lists: kidsPlaylists,
    currentGrades,
  })

  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            const formData = new FormData(e.currentTarget)
            const values = getDataFromFormData(formData)

            const playlistIds: number[] = []
            Object.keys(values).forEach((key) => {
              if (key === "bulletinBoards") return null

              for (const value of values[key]) {
                playlistIds.push(parseInt(value))
              }
            })

            const bulletinGroupIds = await values["bulletinBoards"]?.map(
              (value) =>
                value === PersonalItem.toString()
                  ? "personal"
                  : parseInt(value.split("-")[1])
            )

            const { hasAddedToKidsList, hasSubmittable } =
              await addLessonToPlaylistMutation({
                userPlaylistIds: playlistIds,
                lessonIds: lessonIds || [],
                bulletinGroupIds: bulletinGroupIds || [],
              })

            setValue([])
            addToast({
              content: (
                <>
                  <Text>
                    <Value name="apps.web.src.playlists.components.selectplaylistmodal.les_is_toevoegd_">
                      {`Les is toegevoegd`}
                    </Value>
                  </Text>
                </>
              ),
            })
            onClose()
            if (hasAddedToKidsList && hasSubmittable) {
              setSubmittableToKidsListWarningOpen(true)
            }
          }}
        >
          <Root width="660px">
            <Content>
              <Stack gap="32px">
                <Stack gap="44px">
                  <Stack gap="6px">
                    <Dialog.Title asChild>
                      {lessons.length === 1 ? (
                        <Title>{lessons[0].displayTitle}</Title>
                      ) : (
                        <Title>
                          <Value name="apps.web.src.playlists.components.addlessontolistmodal.lessen_opslaan_op_afspeellijst">
                            Les(sen) opslaan op afspeellijst
                          </Value>
                        </Title>
                      )}
                    </Dialog.Title>
                    <Text bold size={PX16}>
                      <Value name="apps.web.src.playlists.components.addlessontolistmodal.voeg_dit_item_toe_aan_een_lijst" version="4"
                      >
                        Voeg les toe aan je prikbord of bibliotheek.
                      </Value>
                    </Text>
                  </Stack>
                  <Stack gap="16px">
                    <Group gap={"6px"}>
                      {userGroups.length > 1 && (
                        <StyledFilterButton
                          left={<FaSlidersH />}
                          variant="filled"
                          onClick={() => setIsDrawerOpen((prev) => !prev)}
                        >
                          <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalcontent.filter" version="6"
                          >
                            Filter
                          </Value>
                        </StyledFilterButton>
                      )}
                      <FilterButton
                        filters={filters}
                        setFilters={setFilters}
                        value={ADD_TO_PLAYLIST_TYPES.BULLETINBOARDS}
                      >
                        <Value
                          name="apps.web.src.playlists.components.addlessontolistmodal.prikborden"
                          version="1"
                        >
                          Prikbord
                        </Value>
                      </FilterButton>
                      {userGroups.length > 0 && (
                        <FilterButton
                          filters={filters}
                          setFilters={setFilters}
                          value={ADD_TO_PLAYLIST_TYPES.KIDS_PLAYLISTS}
                        >
                          <Value
                            name="apps.web.src.playlists.components.addlessontolistmodal.kidslijsten"
                            version="84"
                          >
                            Kidslijst
                          </Value>
                        </FilterButton>
                      )}
                      <FilterButton
                        filters={filters}
                        setFilters={setFilters}
                        value={ADD_TO_PLAYLIST_TYPES.GROUP_PLAYLISTS}
                      >
                        <Value name="apps.web.src.playlists.components.addlessontolistmodal.lijsten">
                          Lijsten
                        </Value>
                      </FilterButton>
                      {currentUser?.organizationUsers?.[0]?.role !==
                        "MEMBER" && (
                        <FilterButton
                          filters={filters}
                          setFilters={setFilters}
                          value={ADD_TO_PLAYLIST_TYPES.SCHOOL_PLAYLISTS}
                        >
                          <Value name="apps.web.src.playlists.components.addlessontolistmodal.schoollijsten">
                            Schoollijsten
                          </Value>
                        </FilterButton>
                      )}
                    </Group>
                    <ItemList gap="8px">
                      <ScrollArea>
                        <Stack gap="8px">
                          {(filters.length === 0 ||
                            filters.includes(
                              ADD_TO_PLAYLIST_TYPES.BULLETINBOARDS
                            )) && (
                            <ItemListSection
                              title={
                                <Value
                                  name="apps.web.src.playlists.components.addlessontolistmodal.prikborden"
                                  version="1"
                                >
                                  Prikbord
                                </Value>
                              }
                            >
                              {!userGroups || userGroups.length === 0 ? (
                                <Item
                                  id={PersonalItem}
                                  displayTitle={`Mijn prikbord`}
                                  isBulletin
                                  value={value}
                                  setValue={setValue}
                                  usedUserPlaylist={usedUserPlaylist}
                                  usedBulletinBoardGroups={
                                    usedBulletinBoardGroups
                                  }
                                />
                              ) : (
                                filteredUserGroups?.map((group) => {
                                  return (
                                    <Item
                                      id={group.id}
                                      displayTitle={`Prikbord ${group.displayTitle}`}
                                      group={group}
                                      isBulletin
                                      value={value}
                                      setValue={setValue}
                                      usedUserPlaylist={usedUserPlaylist}
                                      usedBulletinBoardGroups={
                                        usedBulletinBoardGroups
                                      }
                                    />
                                  )
                                })
                              )}
                            </ItemListSection>
                          )}
                          {(filters.length === 0 ||
                            filters.includes(
                              ADD_TO_PLAYLIST_TYPES.KIDS_PLAYLISTS
                            )) &&
                            filteredKidsLists &&
                            filteredKidsLists.length > 0 && (
                              <ItemListSection
                                title={
                                  <Value
                                    name="apps.web.src.playlists.components.addlessontolistmodal.kidslijsten"
                                    version="84"
                                  >
                                    Kidslijst
                                  </Value>
                                }
                              >
                                {filteredKidsLists?.map((playlist) => {
                                  if (!playlist.playlist || !playlist.group)
                                    return null
                                  return (
                                    <Item
                                      id={playlist.id}
                                      displayTitle={
                                        playlist.playlist.displayTitle
                                      }
                                      accessLevel={playlist.accessLevel}
                                      type={playlist.type}
                                      group={playlist.group}
                                      playlist={playlist.playlist}
                                      value={value}
                                      setValue={setValue}
                                      usedUserPlaylist={usedUserPlaylist}
                                      usedBulletinBoardGroups={
                                        usedBulletinBoardGroups
                                      }
                                    />
                                  )
                                })}
                              </ItemListSection>
                            )}
                          {(filters.length === 0 ||
                            filters.includes(
                              ADD_TO_PLAYLIST_TYPES.GROUP_PLAYLISTS
                            )) &&
                            filteredGroupLists &&
                            filteredGroupLists.length > 0 && (
                              <ItemListSection
                                title={
                                  <Value name="apps.web.src.playlists.components.addlessontolistmodal.lijsten">
                                    Lijsten
                                  </Value>
                                }
                              >
                                {filteredGroupLists?.map((playlist) => {
                                  if (!playlist.playlist) return null
                                  return (
                                    <Item
                                      id={playlist.id}
                                      displayTitle={
                                        playlist.playlist.displayTitle
                                      }
                                      accessLevel={playlist.accessLevel}
                                      type={playlist.type}
                                      group={
                                        playlist.group
                                          ? playlist.group
                                          : undefined
                                      }
                                      value={value}
                                      setValue={setValue}
                                      usedUserPlaylist={usedUserPlaylist}
                                      usedBulletinBoardGroups={
                                        usedBulletinBoardGroups
                                      }
                                      playlist={playlist.playlist}
                                    />
                                  )
                                })}
                              </ItemListSection>
                            )}
                          {(filters.length === 0 ||
                            filters.includes(
                              ADD_TO_PLAYLIST_TYPES.SCHOOL_PLAYLISTS
                            )) &&
                            filteredSchoolLists &&
                            filteredSchoolLists.length > 0 && (
                              <ItemListSection
                                title={
                                  <Value name="apps.web.src.playlists.components.addlessontolistmodal.schoollijsten">
                                    Schoollijsten
                                  </Value>
                                }
                              >
                                {filteredSchoolLists?.map((playlist) => {
                                  if (!playlist.playlist) return null
                                  return (
                                    <Item
                                      id={playlist.id}
                                      displayTitle={
                                        playlist.playlist.displayTitle
                                      }
                                      accessLevel={playlist.accessLevel}
                                      type={playlist.type}
                                      value={value}
                                      setValue={setValue}
                                      usedUserPlaylist={usedUserPlaylist}
                                      usedBulletinBoardGroups={
                                        usedBulletinBoardGroups
                                      }
                                      playlist={playlist.playlist}
                                    />
                                  )
                                })}
                              </ItemListSection>
                            )}
                        </Stack>
                      </ScrollArea>
                    </ItemList>
                  </Stack>
                </Stack>
                <Group
                  justify="space-between"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <TextButton
                    onClick={() =>
                      setIsNewPlaylistOpen((prevState) => !prevState)
                    }
                  >
                    <TbPlus size={24} />
                    <Value name="apps.web.src.playlists.components.selectplaylistmodal.nieuwe_lijst">
                      Nieuwe lijst
                    </Value>
                  </TextButton>
                  <Group gap="8px" align="center">
                    <Dialog.Close asChild>
                      <CancelButton type="button">
                        <Value name="apps.web.src.playlists.components.selectplaylistmodal.annuleren">
                          Annuleren
                        </Value>
                      </CancelButton>
                    </Dialog.Close>
                    <SubmitButton type="submit" disabled={!value.length}>
                      <Value name="apps.web.src.playlists.components.addlessontolistmodal.bevestig">
                        Bevestig
                      </Value>
                    </SubmitButton>
                  </Group>
                </Group>
              </Stack>
            </Content>
            <Dialog.Close asChild>
              <Close>
                <FaTimes />
              </Close>
            </Dialog.Close>
          </Root>
        </form>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default NewAddToPlaylistModal
