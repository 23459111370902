import { invalidateQuery, useQuery } from "@blitzjs/rpc"
import * as Dialog from "@radix-ui/react-dialog"
import CheckboxBase from "ui/Checkbox"
import { CheckedState } from "@radix-ui/react-checkbox"
import { Separator } from "@radix-ui/react-dropdown-menu"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import {
  DrawerPortal,
  DrawerOverlay,
  DrawerHeader,
  DrawerContent,
  DrawerIconClose,
  DrawerDescription,
  DrawerFooter,
  DrawerClose,
  Drawer,
} from "app/core/components/Drawer"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import {
  ReactNode,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  Suspense,
} from "react"
import { PX14 } from "theme/sizes"
import RoundedButton from "ui/RoundedButton"
import toggleArray from "utils/global/toggleArray"
import getAddableLists from "../queries/getAddableLists"
import AddLessonToListModalContent from "./AddLessonToListModalContent"
import { AddSubmittableToPlaylistWarning } from "./AddSubmittableToPlaylistWarning"
import NewPlaylistModal from "./NewPlaylistModal"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import styled from "@emotion/styled"
import getUserGroups from "app/users/queries/getUserGroups"
import AddListModalContent from "./AddListModalContent"
import { BulletinBoardItemType } from "db"
import { ADD_PLAYLIST_BLUE_COLOR } from "theme/colors"

const Checkbox = styled(CheckboxBase)`
  cursor: pointer;
`

export type AddPlaylist = {
  id: number
  displayTitle: string | ReactNode | null
}

export function AddListModalUncontrolled({
  trigger,
  isOpen,
  setOpen,
  ...props
}: {
  trigger?: ReactNode
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  playlist: AddPlaylist
  type: BulletinBoardItemType
}) {
  const activeGroup = useCurrentGroup()
  const [userGroups] = useQuery(getUserGroups, undefined)
  const [deselectBoxState, setDeselectBoxState] = useState<CheckedState>()
  const [filteredGrades, setFilteredGrades] = useState<number[]>([])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [value, setValue] = useState<string[]>([])

  useEffect(() => {
    if (activeGroup) {
      setFilteredGrades((prevState) =>
        prevState.includes(activeGroup.id)
          ? prevState
          : [...prevState, activeGroup.id]
      )
    }
  }, [activeGroup])

  useEffect(() => {
    filteredGrades.length === 0
      ? setDeselectBoxState(false)
      : filteredGrades.length === userGroups?.length
        ? setDeselectBoxState(true)
        : setDeselectBoxState("indeterminate")
  }, [filteredGrades.length, userGroups?.length])

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={setOpen}>
        {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
        {isOpen ? (
          <Suspense>
            <AddListModalContent
              {...props}
              onClose={() => {
                setOpen(false)
              }}
              filteredGrades={filteredGrades}
              setIsDrawerOpen={setIsDrawerOpen}
              value={value}
              setValue={setValue}
            />
          </Suspense>
        ) : null}
      </Dialog.Root>

      <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
        {isDrawerOpen && (
          <DrawerPortal>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader>
                <DrawerIconClose>
                  <FaTimes size={20} />
                </DrawerIconClose>
              </DrawerHeader>
              <DrawerDescription>
                <Stack gap="12px">
                  <Text bold>
                    <Value name="apps.web.src.studio.components.studiothemegroup.toon_groepen">
                      Toon groepen
                    </Value>
                  </Text>
                  <Separator />
                  <Stack gap="18px">
                    <Group gap="16px" align="center">
                      <Checkbox
                        label={
                          <Text size={PX14}>
                            <Value name="apps.web.src.playlists.components.addlessontolistmodal.deselecteer_alles">
                              (De)selecteer alles
                            </Value>
                          </Text>
                        }
                        checked={deselectBoxState}
                        onCheckedChange={(value) => {
                          if (deselectBoxState) {
                            setFilteredGrades([])
                            setDeselectBoxState(false)
                          } else {
                            userGroups &&
                              setFilteredGrades([
                                ...userGroups.map((userGroup) => userGroup.id),
                              ])
                          }
                        }}
                      />
                    </Group>
                    {userGroups?.map((userGroup) => {
                      const isCurrentGroup = activeGroup?.id === userGroup.id
                      return (
                        <Group
                          gap="16px"
                          align="center"
                          justify="space-between"
                        >
                          <Group gap="16px" align="center">
                            <Checkbox
                              label={
                                <Text
                                  bold={filteredGrades.includes(userGroup.id)}
                                  size={PX14}
                                >
                                  {userGroup.displayTitle}
                                </Text>
                              }
                              value={userGroup.id}
                              checked={filteredGrades.includes(userGroup.id)}
                              onCheckedChange={() =>
                                setFilteredGrades(toggleArray(userGroup.id))
                              }
                            />
                          </Group>
                          <Group align="center" justify="space-between">
                            {isCurrentGroup && (
                              <Text size={PX14} color={ADD_PLAYLIST_BLUE_COLOR}>
                                <Value name="apps.web.src.playlists.components.addlessontolistmodal.huidige_groep">
                                  Huidige groep
                                </Value>
                              </Text>
                            )}
                          </Group>
                        </Group>
                      )
                    })}
                    <Separator />
                  </Stack>
                </Stack>
              </DrawerDescription>
              <DrawerFooter>
                <DrawerClose asChild>
                  <RoundedButton variant="filled">
                    <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                      Filter resultaten
                    </Value>
                  </RoundedButton>
                </DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </DrawerPortal>
        )}
      </Drawer>
    </>
  )
}

function AddListModalControlled(props: {
  trigger: ReactNode
  playlist: AddPlaylist
  type: BulletinBoardItemType
}) {
  const [isOpen, setOpen] = useState(false)
  return (
    <AddListModalUncontrolled isOpen={isOpen} setOpen={setOpen} {...props} />
  )
}

export default AddListModalControlled
