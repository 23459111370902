import { Value } from "@stringtale/react"
import Group from "ui/Group"
import Text from "ui/Text"
import { PX14, PX16 } from "theme/sizes"
import TextLink from "ui/TextLink"
import Hr from "ui/Hr"
import Stack from "ui/Stack"
import Title from "ui/Title"
import ContentSubjectIcon from "app/content-subjects/components/ContentSubjectIcon"
import getContentSubjectTitle from "app/content-subjects/getContentSubjectTitle"
import { TbChevronLeft } from "@react-icons/all-files/tb/TbChevronLeft"
import { ORANGE_COLOR } from "theme/colors"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import * as Grades from "app/schoolbeheer/components/groups/Grades"
import toggleArray from "utils/global/toggleArray"
import Filter from "app/search/components/Filter"
import ParticipationIconFilter from "app/search/components/ParticipationIconFilter"
import * as Accordion from "@radix-ui/react-accordion"
import styled from "@emotion/styled"
import RoundedButtonBase from "ui/RoundedButton"
import { useSearchContext } from "app/search/components/SearchProvider"
import { MutableRefObject } from "react"
import { useQuery } from "@blitzjs/rpc"
import getAllGrades from "app/grades/queries/getAllGrades"
import ContentSubjectButton from "app/search/components/ContentSubjectButton"

const CONTENT_SUBJECTS = ["MUSIC", "DANCE", "DRAMA"] as const

const Panel = styled(Stack)`
  width: 265px;
  gap: 18px;
  flex-shrink: 0;
`

const RoundedButton = styled(RoundedButtonBase)`
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  font-size: ${PX14};
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
`

const SearchFilters = ({
  inputRef,
}: {
  inputRef: MutableRefObject<HTMLInputElement | null>
}) => {
  const [grades] = useQuery(getAllGrades, undefined)
  const {
    contentSubjectType,
    setContentSubjectType,
    groups,
    setGroups,
    clear,
  } = useSearchContext()

  return (
    <Accordion.Root
      type="multiple"
      asChild
      defaultValue={["soort-activiteit", "participants"]}
    >
      <Panel>
        <Group justify="space-between">
          <Text size={PX14}>
            <Value name="apps.web.src.search.components.searchfilters.filters">
              Filters
            </Value>
          </Text>{" "}
          <TextLink
            bold
            size={PX14}
            onClick={() => {
              clear()
              if (inputRef.current) {
                inputRef.current.value = ""
              }
            }}
          >
            <Value name="apps.web.src.search.components.searchfilters.wis_filters">
              Wis filters
            </Value>
          </TextLink>
        </Group>
        <Hr />
        <Stack gap="16px">
          <Title size={PX16}>
            <Value name="apps.web.src.search.components.searchfilters.methode" version="1">
              Vak
            </Value>
          </Title>
          <Stack gap="6px">
            {contentSubjectType ? (
              <>
                <ContentSubjectButton
                  contentType={contentSubjectType}
                  isActive={true}
                  left={
                    <ContentSubjectIcon
                      type={contentSubjectType}
                      color={"white"}
                    />
                  }
                >
                  {getContentSubjectTitle(contentSubjectType)}
                </ContentSubjectButton>
                <RoundedButton
                  color="blue"
                  onClick={() => clear(false)}
                  left={<TbChevronLeft color={ORANGE_COLOR} size={20} />}
                >
                  <Value name="apps.web.src.search.components.searchfilters.alle_methodes">
                    Alle methodes
                  </Value>
                </RoundedButton>
              </>
            ) : (
              CONTENT_SUBJECTS.map((contentSubject) => {
                const isActive = contentSubjectType === contentSubject
                return (
                  <ContentSubjectButton
                    contentType={contentSubject}
                    onClick={() => setContentSubjectType(contentSubject)}
                    left={
                      <ContentSubjectIcon
                        type={contentSubject}
                        color={
                          isActive
                            ? "white"
                            : getContentSubjectColor(contentSubject)
                        }
                      />
                    }
                  >
                    {getContentSubjectTitle(contentSubject)}
                  </ContentSubjectButton>
                )
              })
            )}
          </Stack>
        </Stack>
        <Hr />
        <Stack gap="16px">
          <Title size={PX16}>
            <Value name="apps.web.src.search.components.searchfilters.groep">
              Groep
            </Value>
          </Title>
          <Group wrap gap="6px">
            {grades.map((grade) => (
              <Grades.Root
                checked={groups.includes(grade.id)}
                onCheckedChange={() => setGroups(toggleArray(grade.id))}
              >
                {grade.number}
              </Grades.Root>
            ))}
          </Group>
        </Stack>
        <Hr />
        <Filter slug="soort-activiteit" />
        <Filter slug="leergebied-vakken" />
        <Filter slug="thema" />
        {contentSubjectType ? (
          <ParticipationIconFilter contentSubjectType={contentSubjectType} />
        ) : null}
        {contentSubjectType === "MUSIC" && (
          <>
            <Filter slug="domeinen" />
            <Filter slug="soort-liedje" />
            <Filter slug="muziekstijl" />
            <Filter slug="tempo" />
            <Filter slug="orkest-arrangement" />
          </>
        )}
        {contentSubjectType === "DANCE" && (
          <>
            <Filter slug="danselementen" />
            <Filter slug="dansstijl" />
          </>
        )}
        {contentSubjectType === "DRAMA" && (
          <>
            <Filter slug="spelelementen" />
            <Filter slug="vaardigheden" />
          </>
        )}

        <Filter slug="bouwsteen-burgerschap" />
      </Panel>
    </Accordion.Root>
  )
}

export default SearchFilters
