import { UserRole } from "db"
import getAnalyticsContentGroups, {
  ContentGroupsObject,
} from "./getAnalyticsContentGroups"
import getAnalyticsUserGroups, {
  UserGroupsObject,
} from "./getAnalyticsUserGroups"

const blankAnalyticsObject = {
  contentType: undefined,
  slug: undefined,
  parentSlug: undefined,
  subject: undefined,
  isPublicPage: false,
  isKidsPage: false,
  eventType: undefined,
  keywords: undefined,
  isLearningLineLesson: undefined,
  learningLine: undefined,
  learningLineSubject: undefined,
  learningLineSlug: undefined,
  ...getAnalyticsContentGroups([]),
  ...getAnalyticsUserGroups([]),
  userType: "UNKNOWN",
} as AnalyticsObject

// Create a type based on the keys of blankAnalyticsObject
export type AnalyticsObject = {
  contentType?:
    | "slide"
    | "learningLineOverview"
    | "lessonDetails"
    | "learningLineDetails"
    | "teacherPage"
    | "categoriesOverview"
    | "category"
    | "theme"
    | undefined
  eventType?: "search" | undefined
  keywords?: string | undefined
  slug?: string | undefined
  subject?: "MUSIC" | "DRAMA" | "DANCE" | undefined
  parentSlug?: string | undefined
  isLearningLineLesson?: boolean | undefined
  learningLine?: string | undefined
  learningLineSubject?: "MUSIC" | "DRAMA" | "DANCE" | undefined
  learningLineSlug?: string | undefined
  userType: UserRole | "PUBLIC" | "UNKNOWN"
  isPublicPage: boolean
  isKidsPage: boolean
} & ContentGroupsObject &
  UserGroupsObject

// Ensure that analyticsValues can only have keys from AnalyticsObject
export default function generateAnalyticsObject(
  analyticsValues: Partial<AnalyticsObject> | undefined
) {
  return {
    ...blankAnalyticsObject,
    ...analyticsValues, // spread the new values into the object
  }
}
