import { invalidateQuery, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import getAllGrades from "app/grades/queries/getAllGrades"
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react"
import { PX14 } from "theme/sizes"
import CheckboxBase from "ui/Checkbox"
import Group from "ui/Group"
import { Overlay as OverlayBase } from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import { Separator } from "ui/Separator"
import Stack from "ui/Stack"
import Text from "ui/Text"
import toggleArray from "utils/global/toggleArray"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerIconClose,
  DrawerOverlay,
  DrawerPortal,
} from "../../core/components/Drawer"
import getAddableLists from "../queries/getAddableLists"
import AddLessonToListModalContent from "./AddLessonToListModalContent"
import NewPlaylistModal from "./NewPlaylistModal"
import { ADD_PLAYLIST_BLUE_COLOR, MODAL_Z_INDEX } from "theme/consts"
import { CheckedState } from "@radix-ui/react-checkbox"
import { AddSubmittableToPlaylistWarning } from "./AddSubmittableToPlaylistWarning"
import getUserGroups from "app/users/queries/getUserGroups"

const Checkbox = styled(CheckboxBase)`
  cursor: pointer;
`

const Overlay = styled(OverlayBase)`
  z-index: ${MODAL_Z_INDEX} + 1;
`

export type AddToPlaylistLesson = {
  id: number
  displayTitle: string | null
}

export function AddLessonToListModalUncontrolled({
  trigger,
  isOpen,
  setOpen,
  ...props
}: {
  trigger?: ReactNode
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  lessons: AddToPlaylistLesson[]
}) {
  const [
    isSubmittableToKidsListWarningOpen,
    setSubmittableToKidsListWarningOpen,
  ] = useState(false)
  const activeGroup = useCurrentGroup()
  const [userGroups] = useQuery(getUserGroups, undefined)
  const [deselectBoxState, setDeselectBoxState] = useState<CheckedState>()
  const [currentGrades, setGrades] = useState<number[]>([])
  const [isNewPlaylistOpen, setIsNewPlaylistOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [value, setValue] = useState<string[]>([])

  useEffect(() => {
    if (activeGroup) {
      setGrades((prevState) =>
        prevState.includes(activeGroup.id)
          ? prevState
          : [...prevState, activeGroup.id]
      )
    }
  }, [activeGroup])

  useEffect(() => {
    currentGrades.length === 0
      ? setDeselectBoxState(false)
      : currentGrades.length === userGroups?.length
        ? setDeselectBoxState(true)
        : setDeselectBoxState("indeterminate")
  }, [currentGrades.length, userGroups?.length])

  return (
    <>
      <Dialog.Root
        open={isSubmittableToKidsListWarningOpen}
        onOpenChange={setSubmittableToKidsListWarningOpen}
      >
        <Suspense>
          {isSubmittableToKidsListWarningOpen ? (
            <AddSubmittableToPlaylistWarning lessons={props.lessons} />
          ) : null}
        </Suspense>
      </Dialog.Root>
      <Dialog.Root open={isOpen} onOpenChange={setOpen}>
        {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
        {isOpen ? (
          <Suspense>
            <AddLessonToListModalContent
              {...props}
              onClose={() => setOpen(false)}
              setSubmittableToKidsListWarningOpen={
                setSubmittableToKidsListWarningOpen
              }
              setIsNewPlaylistOpen={setIsNewPlaylistOpen}
              currentGrades={currentGrades}
              setIsDrawerOpen={setIsDrawerOpen}
              value={value}
              setValue={setValue}
            />
          </Suspense>
        ) : null}
      </Dialog.Root>
      <Dialog.Root open={isNewPlaylistOpen} onOpenChange={setIsNewPlaylistOpen}>
        {isNewPlaylistOpen && (
          <NewPlaylistModal
            onClose={(newUserList) => {
              if (!newUserList) return

              setIsNewPlaylistOpen(false)
              invalidateQuery(getAddableLists)
              setValue((prevState) => {
                console.log(newUserList)
                console.log(prevState.includes(newUserList.id.toString()))
                if (prevState.includes(newUserList.id.toString()))
                  return prevState
                return [...prevState, newUserList.id.toString()]
              })
            }}
          />
        )}
      </Dialog.Root>
      <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
        {isDrawerOpen && (
          <DrawerPortal>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader>
                <DrawerIconClose>
                  <FaTimes size={20} />
                </DrawerIconClose>
              </DrawerHeader>
              <DrawerDescription>
                <Stack gap="12px">
                  <Text bold>
                    <Value name="apps.web.src.studio.components.studiothemegroup.toon_groepen">
                      Toon groepen
                    </Value>
                  </Text>
                  <Separator />
                  <Stack gap="18px">
                    <Group gap="16px" align="center">
                      <Checkbox
                        label={
                          <Text size={PX14}>
                            <Value name="apps.web.src.playlists.components.addlessontolistmodal.deselecteer_alles">
                              (De)selecteer alles
                            </Value>
                          </Text>
                        }
                        checked={deselectBoxState}
                        onCheckedChange={(value) => {
                          if (deselectBoxState) {
                            setGrades([])
                            setDeselectBoxState(false)
                          } else {
                            userGroups &&
                              setGrades([
                                ...userGroups.map((userGroup) => userGroup.id),
                              ])
                          }
                        }}
                      />
                    </Group>
                    {userGroups?.map((userGroup) => {
                      const isCurrentGroup = activeGroup?.id === userGroup.id
                      return (
                        <Group
                          gap="16px"
                          align="center"
                          justify="space-between"
                        >
                          <Group gap="16px" align="center">
                            <Checkbox
                              label={
                                <Text
                                  bold={currentGrades.includes(userGroup.id)}
                                  size={PX14}
                                >
                                  {userGroup.displayTitle}
                                </Text>
                              }
                              value={userGroup.id}
                              checked={currentGrades.includes(userGroup.id)}
                              onCheckedChange={() =>
                                setGrades(toggleArray(userGroup.id))
                              }
                            />
                          </Group>
                          <Group align="center" justify="space-between">
                            {isCurrentGroup && (
                              <Text size={PX14} color={ADD_PLAYLIST_BLUE_COLOR}>
                                <Value name="apps.web.src.playlists.components.addlessontolistmodal.huidige_groep">
                                  Huidige groep
                                </Value>
                              </Text>
                            )}
                          </Group>
                        </Group>
                      )
                    })}
                    <Separator />
                  </Stack>
                </Stack>
              </DrawerDescription>
              <DrawerFooter>
                <DrawerClose asChild>
                  <RoundedButton variant="filled">
                    <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                      Filter resultaten
                    </Value>
                  </RoundedButton>
                </DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </DrawerPortal>
        )}
      </Drawer>
    </>
  )
}

function AddLessonToListModal(props: {
  lessons: AddToPlaylistLesson[]
  trigger: ReactNode
}) {
  const [isOpen, setOpen] = useState(false)
  return (
    <AddLessonToListModalUncontrolled
      {...props}
      isOpen={isOpen}
      setOpen={setOpen}
    />
  )
}

export default AddLessonToListModal
