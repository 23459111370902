import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaSlidersH } from "@react-icons/all-files/fa/FaSlidersH"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import useToast from "app/toasts/hooks/useToast"
import {
  BulletinBoardItemType,
  Group as TGroup,
  UserListAccessLevel,
  UserListType,
} from "db"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import {
  ADD_PLAYLIST_BLUE_COLOR,
  BORDER_GREY_COLOR,
  DARK_BLUE_COLOR,
  DARK_ORANGE_COLOR,
  GREEN_COLOR,
  LIGHT_GREY_COLOR,
  LIGHT_ORANGE_COLOR,
  MODAL_OVERLAY_Z_INDEX,
  ORANGE_COLOR,
  RED_COLOR,
} from "theme/consts"
import { PX12, PX14, PX16 } from "theme/sizes"
import CheckboxBase from "ui/Checkbox"
import Group from "ui/Group"
import HrBase from "ui/Hr"
import {
  CancelButton,
  Close,
  Content as ContentBase,
  Overlay as OverlayBase,
  Root as RootBase,
  ScrollArea as ScrollAreaBase,
  SubmitButton,
  Title,
} from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import getDataFromFormData from "utils/form/getDataFromFormData"
import { ADD_LIST_TYPES } from "../consts"
import addListToLibraryOrBulletinboard from "../mutations/addListToLibraryOrBulletinboard"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import getListsByGroups from "../queries/getListsByGroups"
import getListTypeDisplayTitle from "../utils/getListTypeDisplayTitle"
import {
  getUsedBulletinGroups,
  getUsedLibraryGroups,
} from "../utils/getUsedBulletinAndLibraryGroups"
import { AddPlaylist } from "./AddListModal"
import PlaylistMenuCircleBase from "./PlaylistMenuCircle"
import getThemeGroup from "app/themes/queries/getThemeGroup"

export const PersonalItem = Symbol("personal")

type AddListModalProps = {
  onClose: () => void
  playlist: AddPlaylist
  filteredGrades: number[]
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  value: string[]
  setValue: Dispatch<SetStateAction<string[]>>
  type: BulletinBoardItemType
}

const Root = styled(RootBase)`
  font-size: ${PX14};
  flex: 1;
  z-index: ${MODAL_OVERLAY_Z_INDEX - 1};
`

const Overlay = styled(OverlayBase)`
  z-index: ${MODAL_OVERLAY_Z_INDEX - 2};
`

const Content = styled(ContentBase)`
  flex-direction: column;
  padding: 32px;
`

const Hr = styled(HrBase)`
  color: ${BORDER_GREY_COLOR};
`

const Label = styled("label")`
  width: 100%;
  padding: 0 0 0 16px;
  box-sizing: border-box;
`

const ItemRoot = styled("div")<{ disabled?: boolean; checked?: boolean }>`
  display: grid;
  grid-template-columns: 19px auto;
  background: white;
  align-items: center;
  height: 46px;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 16px;
  ${({ checked }) =>
    checked &&
    css`
      background-color: ${LIGHT_ORANGE_COLOR};
    `}
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
          ${Label} {
            cursor: default;
          }
          ${Checkbox} {
            cursor: default;
          }
        `
      : css`
          color: inherit;
          ${Label} {
            cursor: pointer;
          }
          ${Checkbox} {
            cursor: pointer;
          }
        `};
`

const ItemList = styled(Stack)`
  border: 1px solid #b2b1c9;
  border-radius: 8px;
  overflow: hidden;
  height: 472px;
`

const Checkbox = styled(CheckboxBase)`
  cursor: pointer;
`

const ScrollArea = styled(ScrollAreaBase)`
  padding: 8px;
`

const ItemListSection = ({ title, children }) => {
  return (
    <Stack gap="8px">
      <Group
        justify="center"
        align="center"
        gap="8px"
        style={{ padding: "0 0 0 8px" }}
      >
        <Text color={ADD_PLAYLIST_BLUE_COLOR} size={PX12}>
          {title}
        </Text>
        <Hr />
      </Group>
      <Stack gap="4px">{children}</Stack>
    </Stack>
  )
}

const StyledFilterButton = styled(RoundedButton)<{
  checked?: boolean
  variant?: "filled" | "bordered"
}>`
  border-radius: 8px;
  height: 46px;
  box-sizing: border-box;
  border: 1px solid ${LIGHT_GREY_COLOR};
  ${Text} {
    font-weight: 500;
  }

  &:hover {
    background-color: ${({ variant }) =>
      variant === "filled" ? DARK_ORANGE_COLOR : LIGHT_ORANGE_COLOR};
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${LIGHT_ORANGE_COLOR};
      border-color: ${ORANGE_COLOR};
      color: ${ORANGE_COLOR};
      ${Text} {
        font-weight: 600;
      }
      &:hover {
        border-color: ${RED_COLOR};
        color: ${RED_COLOR};
      }
    `}
`

const LabelContent = styled("div")`
  display: grid;
  grid-template-columns: 32px auto;
  gap: 8px;
  justify-content: center;
  align-items: center;
`

const PlaylistMenuCircle = styled(PlaylistMenuCircleBase)`
  height: 32px;
  width: 32px;
`

const Item = ({
  id,
  displayTitle,
  accessLevel,
  type,
  itemType,
  group,
  value,
  setValue,
  usedLibraryGroups,
  usedBulletinBoardGroups,
}: {
  id: number | typeof PersonalItem
  displayTitle?: string | null
  accessLevel?: UserListAccessLevel
  type?: UserListType
  itemType: "bulletin" | "library"
  group?: TGroup
  value: string[]
  setValue: Dispatch<SetStateAction<string[]>>
  usedLibraryGroups: {
    id: number | typeof PersonalItem
  }[]
  usedBulletinBoardGroups: { id: number | typeof PersonalItem }[]
}) => {
  // Disable item if it is already added to group OR if it already exists as a personal item
  const disabled =
    (itemType === "bulletin" &&
      (accessLevel === "SCHOOL" ||
        usedBulletinBoardGroups.some((group) => group.id === id) ||
        (id === PersonalItem && usedBulletinBoardGroups?.length > 0))) ||
    (itemType === "library" &&
      (usedLibraryGroups.some((group) => group.id === id) ||
        (id === PersonalItem && usedLibraryGroups?.length > 0)))

  const currentValue = `${itemType}-${id.toString()}`

  return (
    <ItemRoot disabled={disabled} checked={value.includes(currentValue)}>
      <Checkbox
        size="large"
        id={currentValue}
        value={currentValue}
        checked={value.includes(currentValue)}
        onCheckedChange={() => {
          setValue((prevState) => {
            if (value.includes(currentValue)) {
              return prevState.filter((i) => i !== currentValue)
            }
            return [...prevState, currentValue]
          })
        }}
        name={`${itemType}[]`}
        disabled={disabled}
      />
      <Label htmlFor={currentValue}>
        <Group justify="space-between" align="flex-start">
          <LabelContent>
            {itemType === "bulletin" ? (
              <PlaylistMenuCircle
                color={ORANGE_COLOR}
                accessLevel={"KIDS"}
                type={"PLAYLIST"}
              />
            ) : (
              <PlaylistMenuCircle
                color={GREEN_COLOR}
                accessLevel={"KIDS"}
                type={"PLAYLIST"}
              />
            )}
            <Stack grow align="flex-start">
              <Text bold>{displayTitle}</Text>
              {itemType === "library" && accessLevel && type && (
                <Text color={ADD_PLAYLIST_BLUE_COLOR} size={PX12}>
                  {accessLevel === "GROUP" && group
                    ? `${group.displayTitle} • ${getListTypeDisplayTitle(accessLevel, type)}`
                    : getListTypeDisplayTitle(accessLevel, type)}
                </Text>
              )}
            </Stack>
          </LabelContent>
          {disabled ? (
            <Text color={DARK_BLUE_COLOR} size={PX12}>
              <Value name="apps.web.src.playlists.components.addlistmodalcontent.afspeellijst_is_al_opgeslagen">
                Afspeellijst is al opgeslagen
              </Value>
            </Text>
          ) : null}
        </Group>
      </Label>
    </ItemRoot>
  )
}

const AddListModalContent = ({
  playlist,
  onClose,
  filteredGrades,
  setIsDrawerOpen,
  value,
  setValue,
  type,
}: AddListModalProps) => {
  const addToast = useToast()
  const currentUser = useCurrentUser()
  const [addListToLibraryOrBulletinboardMutation] = useMutation(
    addListToLibraryOrBulletinboard
  )
  const [getListsByGroupsResult] = useQuery(getListsByGroups, undefined, {
    keepPreviousData: true,
  })

  const [filters, setFilters] = useState<ADD_LIST_TYPES[]>([])

  const playlistToAddId = playlist.id

  const usedLibraryGroups = useMemo(
    () =>
      getUsedLibraryGroups({ getListsByGroupsResult, playlistToAddId, type }),
    [getListsByGroupsResult, playlistToAddId, type]
  )

  const usedBulletinBoardGroups = useMemo(
    () =>
      getUsedBulletinGroups({ getListsByGroupsResult, playlistToAddId, type }),
    [getListsByGroupsResult, playlistToAddId, type]
  )

  if (!getListsByGroupsResult) return null

  const { userGroups } = getListsByGroupsResult

  const filteredUserGroups =
    filteredGrades.length < 1
      ? userGroups
      : userGroups.filter((userGroup) => filteredGrades.includes(userGroup.id))

  return (
    <Dialog.Portal>
      <Dialog.Overlay asChild>
        <Overlay />
      </Dialog.Overlay>
      <Dialog.Content asChild>
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            const formData = new FormData(e.currentTarget)
            const values = getDataFromFormData(formData)

            const libraryGroupIds = await values["library"]?.map((value) =>
              value.split("-")[1] === PersonalItem.toString()
                ? "personal"
                : parseInt(value.split("-")[1])
            )

            const bulletinGroupIds = await values["bulletin"]?.map((value) => {
              return value.split("-")[1] === PersonalItem.toString()
                ? "personal"
                : parseInt(value.split("-")[1])
            })
            await addListToLibraryOrBulletinboardMutation({
              playlistId: playlist.id,
              type: type,
              bulletinGroupIds: bulletinGroupIds || [],
              libraryGroupIds: libraryGroupIds || [],
            })

            setValue([])
            addToast({
              content: (
                <>
                  <Text>
                    <Value name="apps.web.src.playlists.components.addlistmodalcontent.lijst_opgeslagen">
                      Lijst opgeslagen
                    </Value>
                  </Text>
                </>
              ),
            })
            await invalidateQuery(getGroupedUserPlaylists)
            await invalidateQuery(getBulletinBoardItems)
            await invalidateQuery(getThemeGroup)
            onClose()
          }}
        >
          <Root width="660px">
            <Content>
              <Stack gap="32px">
                <Stack gap="44px">
                  <Stack gap="6px">
                    <Dialog.Title asChild>
                      <Title>{playlist.displayTitle}</Title>
                    </Dialog.Title>
                    <Text bold size={PX16}>
                      <Value
                        name="apps.web.src.playlists.components.addlistmodalcontent.voeg_dit_item_toe_aan_een_lijst"
                        version="94"
                      >
                        Voeg lijst toe aan je prikbord of bibliotheek.
                      </Value>
                    </Text>
                  </Stack>
                  <Stack gap="16px">
                    {userGroups.length > 1 && (
                      <div>
                        <StyledFilterButton
                          left={<FaSlidersH />}
                          variant="filled"
                          onClick={() => setIsDrawerOpen((prev) => !prev)}
                        >
                          <Value
                            name="apps.web.src.bulletinboard.components.addlistmodalcontent.filter"
                            version="1"
                          >
                            Groepen
                          </Value>
                        </StyledFilterButton>
                      </div>
                    )}

                    <ItemList gap="8px">
                      <ScrollArea>
                        <Stack gap="8px">
                          {!userGroups || userGroups.length === 0 ? (
                            // This content is shown for users without groups, be it admin, students or just users without groups
                            <>
                              {(filters.length === 0 ||
                                filters.includes(
                                  ADD_LIST_TYPES.BULLETINBOARDS
                                )) && (
                                <ItemListSection
                                  title={
                                    <Value name="apps.web.src.playlists.components.addlistmodalcontent.prikborden">
                                      Prikborden
                                    </Value>
                                  }
                                >
                                  <Item
                                    id={PersonalItem}
                                    displayTitle={`Mijn prikbord`}
                                    itemType={"bulletin"}
                                    value={value}
                                    setValue={setValue}
                                    usedLibraryGroups={usedLibraryGroups}
                                    usedBulletinBoardGroups={
                                      usedBulletinBoardGroups
                                    }
                                  />
                                </ItemListSection>
                              )}
                              {(filters.length === 0 ||
                                filters.includes(ADD_LIST_TYPES.LIBRARY)) && (
                                <ItemListSection
                                  title={
                                    <Value name="apps.web.src.playlists.components.addlistmodalcontent.bibliotheken">
                                      Bibliotheken
                                    </Value>
                                  }
                                >
                                  <Item
                                    id={PersonalItem}
                                    displayTitle={`Mijn bibliotheek`}
                                    itemType={"library"}
                                    value={value}
                                    setValue={setValue}
                                    usedLibraryGroups={usedLibraryGroups}
                                    usedBulletinBoardGroups={
                                      usedBulletinBoardGroups
                                    }
                                  />
                                </ItemListSection>
                              )}
                            </>
                          ) : (
                            // This content is shown for users with groups
                            <>
                              {(filters.length === 0 ||
                                filters.includes(
                                  ADD_LIST_TYPES.BULLETINBOARDS
                                )) && (
                                <ItemListSection
                                  title={
                                    <Value name="apps.web.src.playlists.components.addlistmodalcontent.prikborden">
                                      Prikborden
                                    </Value>
                                  }
                                >
                                  {filteredUserGroups?.map((group) => {
                                    return (
                                      <Item
                                        id={group.id}
                                        displayTitle={`Prikbord ${group.displayTitle}`}
                                        group={group}
                                        itemType={"bulletin"}
                                        value={value}
                                        setValue={setValue}
                                        usedLibraryGroups={usedLibraryGroups}
                                        usedBulletinBoardGroups={
                                          usedBulletinBoardGroups
                                        }
                                      />
                                    )
                                  })}
                                </ItemListSection>
                              )}
                              {(filters.length === 0 ||
                                filters.includes(ADD_LIST_TYPES.LIBRARY)) && (
                                <ItemListSection
                                  title={
                                    <Value name="apps.web.src.playlists.components.addlistmodalcontent.bibliotheken">
                                      Bibliotheken
                                    </Value>
                                  }
                                >
                                  {filteredUserGroups?.map((group) => {
                                    return (
                                      <Item
                                        id={group.id}
                                        displayTitle={`Bibliotheek ${group.displayTitle}`}
                                        itemType={"library"}
                                        group={group}
                                        value={value}
                                        setValue={setValue}
                                        usedLibraryGroups={usedLibraryGroups}
                                        usedBulletinBoardGroups={
                                          usedBulletinBoardGroups
                                        }
                                      />
                                    )
                                  })}
                                </ItemListSection>
                              )}
                            </>
                          )}
                        </Stack>
                      </ScrollArea>
                    </ItemList>
                  </Stack>
                </Stack>
                <Group
                  justify="flex-end"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <Group gap="8px" align="center">
                    <Dialog.Close asChild>
                      <CancelButton type="button">
                        <Value name="apps.web.src.playlists.components.addlistmodalcontent.annuleren">
                          Annuleren
                        </Value>
                      </CancelButton>
                    </Dialog.Close>
                    <SubmitButton type="submit" disabled={!value.length}>
                      <Value name="apps.web.src.playlists.components.addlistmodalcontent.bevestig">
                        Bevestig
                      </Value>
                    </SubmitButton>
                  </Group>
                </Group>
              </Stack>
            </Content>
            <Dialog.Close asChild>
              <Close>
                <FaTimes />
              </Close>
            </Dialog.Close>
          </Root>
        </form>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export default AddListModalContent
