import Head from "next/head"
import { PropsWithChildren, ReactNode } from "react"
import getGTMToken from "app/core/queries/getGTMToken"
import { useQuery } from "@blitzjs/rpc"
import Script from "next/script"
import { AnalyticsObject } from "app/analytics/utils/generateAnalyticsObject"
import Analytics from "../components/Analytics"

const Layout = ({
  title,
  analyticsObject,
  children,
}: PropsWithChildren<{
  title?: ReactNode
  analyticsObject?: Partial<AnalyticsObject>
}>) => {
  const [token] = useQuery(getGTMToken, undefined, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  })
  return (
    <>
      <Head>
        {/* eslint-disable-next-line @stringtale/no-unwrapped-jsx-text */}
        <title>{title ? `${title} - 123ZING` : "123ZING"}</title>
        <link rel="icon" href="/favicon.ico?v=1692089659" />
      </Head>
      {token ? (
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${token}');`}
        </Script>
      ) : null}
      <Analytics analyticsObject={analyticsObject} />
      {children}
    </>
  )
}

export default Layout
