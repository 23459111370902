import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import FollowPlaylistToGroupModal from "app/playlists/components/FollowPlaylistToGroupModal"
import getThemeGroup from "app/themes/queries/getThemeGroup"
import toggleFollowThemePlaylist from "../mutations/followThemePlaylist"

type Props = {
  themeGroupId: number
  onClose: () => void
}

export default function FollowThemeToGroupsModal({
  themeGroupId,
  onClose,
}: Props) {
  const [toggleFollowThemePlaylistMutation] = useMutation(
    toggleFollowThemePlaylist
  )
  return (
    <FollowPlaylistToGroupModal
      themeGroupId={themeGroupId}
      onClose={onClose}
      onSubmit={async (groupIds) => {
        await toggleFollowThemePlaylistMutation({
          themeGroupId,
          groupIds,
        })
        await invalidateQuery(getThemeGroup)
      }}
    >
    </FollowPlaylistToGroupModal>
  )
}
