import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import { Value } from "@stringtale/react"
import { BulletinBoardItemType } from "db"
import React from "react"
import RoundedButtonBase from "ui/RoundedButton"
import BulletinBoardLLCard from "./BulletinBoardLLCard"
import Group from "ui/Group"
import Text from "ui/Text"
import { Content, Root, DescriptionWrapper } from "./BulletinAddListItem"
import Stack from "ui/Stack"
import { PX16, PX12 } from "theme/sizes"
import styled from "@emotion/styled"
import addBulletinBoardItem from "../mutations/addBulletinBoardItem"
import getBulletinBoardItems, {
  GetBulletinBoardItemsResult,
} from "../queries/getBulletinBoardItems"
import { DARKER_GREY_COLOR } from "theme/colors"
import Loader from "ui/Loader"

const PreviewSize = styled.div`
  min-width: 200px;
  height: 200px;
  pointer-events: none;
`

const RoundedButton = styled(RoundedButtonBase)`
  width: max-content;
`

const BulletinAddModalWidgetItem = ({
  bulletinBoardItems,
}: {
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [addBulletinBoardItemMutation, addBulletinBoardItemRes] =
    useMutation(addBulletinBoardItem)
  const hasLLWidget = !!bulletinBoardItems.find((item) => item.type === "LL")
  return (
    <Root>
      <Group gap="20px">
        <PreviewSize>
          <BulletinBoardLLCard
            preview
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          />
        </PreviewSize>
        <Content>
          <Stack gap="24px">
            <Stack gap="4px">
              <Text size={PX12}>
                <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalwidgetitem.widget">
                  Widget
                </Value>
              </Text>
              <Stack gap="12px">
                <Text size={PX16} bold>
                  <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalwidgetitem.leerlijnen_snelle_link">
                    Leerlijnen snelle link
                  </Value>
                </Text>
              </Stack>
              <DescriptionWrapper>
                <Text size={PX12}>
                  <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalwidgetitem.volg_je_de_leerlijn">
                    Volg je de leerlijn? Dan is deze widget super handig! Zet de
                    de widget op je prikbord. Je kunt daarna snel naar de
                    leerlijn die je volgt.
                  </Value>
                </Text>
              </DescriptionWrapper>
            </Stack>
            <RoundedButton
              size="small"
              variant="filled"
              style={
                hasLLWidget
                  ? { opacity: 1, backgroundColor: DARKER_GREY_COLOR }
                  : {}
              }
              disabled={hasLLWidget}
              onClick={async () => {
                await addBulletinBoardItemMutation({
                  type: BulletinBoardItemType.LL,
                })
                await invalidateQuery(getBulletinBoardItems)
              }}
            >
              {hasLLWidget ? (
                <Value name="app.bulletinboard.components.bulletinaddmodalwidgetitem.maximaal_1_op_prikbord">
                  Maximaal 1 op prikbord
                </Value>
              ) : addBulletinBoardItemRes.isLoading ? (
                <Loader color="#FFFFFF" />
              ) : (
                <Value name="app.bulletinboard.components.bulletinaddmodalwidgetitem.zet_op_prikbord">
                  Zet op prikbord
                </Value>
              )}
            </RoundedButton>
          </Stack>
        </Content>
      </Group>
    </Root>
  )
}

export default BulletinAddModalWidgetItem
