import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import SelectGroupModal from "app/groups/components/SelectGroupModal"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import { GroupSelectItem } from "./FollowPlaylistToGroupModal"
import addSharedPlaylistToGroups from "../mutations/addSharedPlaylistToGroups"
import { PropsWithChildren } from "react"
import { Value } from "@stringtale/react"
import useToast from "app/toasts/hooks/useToast"

type Props = PropsWithChildren<{
  onClose: () => void
  playlistId: number
}>

export default function AddSharedPlaylistToGroupModal({
  playlistId,
  onClose,
  ...props
}: Props) {
  const [addSharedPlaylistToGroupsMutation] = useMutation(
    addSharedPlaylistToGroups
  )

  const addToast = useToast()
  return (
    <SelectGroupModal
      onClose={onClose}
      onSubmit={async (groupIds) => {
        await addSharedPlaylistToGroupsMutation({
          groupIds,
          playlistId,
        })
        await invalidateQuery(getGroupedUserPlaylists)
        addToast({
          content: (
            <Value name="apps.web.src.themes.components.addsharedplaylisttogroupsmodal.lijst_is_gekoppeld_aan_groepen">
              {`Lijst is gekoppeld aan groep(en).`}
            </Value>
          ),
        })
        onClose()
      }}
      onRenderItem={(props) => <GroupSelectItem {...props} />}
      {...props}
    />
  )
}
