import ContentSubjectIcon from "web/src/content-subjects/components/ContentSubjectIcon"
import getContentSubjectColor from "web/src/content-subjects/getContentSubjectColor"
import { Title, TitleWrapper } from "./LessonListItem"
import { ContentSubjectType } from "db"

type Props = {
  title: string
  contentSubject: ContentSubjectType
}

const LessonListItemTitle = ({ title, contentSubject }: Props) => {
  return (
    <TitleWrapper>
      <ContentSubjectIcon
        type={contentSubject}
        color={getContentSubjectColor(contentSubject)}
      />
      <Title>{title}</Title>
    </TitleWrapper>
  )
}

export default LessonListItemTitle
