import * as Dialog from "@radix-ui/react-dialog"

import styled from "@emotion/styled"
import { FaSearch } from "@react-icons/all-files/fa/FaSearch"
import { TbBackspaceFilled } from "@react-icons/all-files/tb/TbBackspaceFilled"
import { Value } from "@stringtale/react"
import { RADIUS } from "app/playlists/consts"
import { AnimatePresence, motion } from "framer-motion"
import { Dispatch, SetStateAction, Suspense, useRef, useState } from "react"
import {
  DARKER_GREY_COLOR,
  DARK_BLUE_COLOR,
  LIGHT_ORANGE_COLOR,
  MODAL_OVERLAY_Z_INDEX,
  MODAL_Z_INDEX,
  ORANGE_COLOR,
  PX14,
} from "theme/consts"
import { poppins } from "theme/fonts"
import { MODAL_BOX_SHADOW } from "theme/styles"
import Button from "ui/Button"
import Group from "ui/Group"
import InputBase from "ui/Input"
import * as Modal from "ui/Modal"
import * as ScrollArea from "ui/ScrollArea"
import Stack from "ui/Stack"
import Text from "ui/Text"
// import AddLessonListItem from "./AddLessonListItem"
import * as Tabs from "@radix-ui/react-tabs"
import { parseAsBoolean, useQueryState } from "nuqs"
// import Tab from "ui/Tab"
import { useAtom } from "jotai"
import { TABS } from "../consts"
import { addBulletinItemModalOpenAtom } from "../state"
import BulletinAddModalContent from "./BulletinAddModalContent"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import SearchFilters from "app/search/components/SearchFilters"

const Root = styled.div`
  width: calc(100vw - 142px);
  height: calc(100vh - 50px);
  max-width: 1020px;
  min-width: 925px;
  position: fixed;
  color: ${DARK_BLUE_COLOR};

  justify-self: center;

  top: 25px;
  left: 25px;
  bottom: 25px;
  right: 25px;
  z-index: ${MODAL_Z_INDEX};
  background-color: white;
  border-radius: ${RADIUS};
  font-family: ${poppins.style.fontFamily};
  ${MODAL_BOX_SHADOW};

  @media screen and (max-width: 1150px) {
    width: unset;
  }
`.withComponent(motion.div)

const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* grid-template-rows: 45px min-content auto; */
  gap: 20px;
  padding: 40px;
  box-sizing: border-box;
  height: inherit;
  overflow: hidden;
  height: 100%;
`

const Filters = styled.div`
  /* background-color: red; */
  height: 100%;
`

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: ${MODAL_OVERLAY_Z_INDEX};
`.withComponent(motion.div)

const Header = styled(Stack)``

const Search = styled(Stack)``

const InputWrapper = styled(Group)`
  width: 100%;
  align-items: stretch;
`

const InputButton = styled(Button)`
  background-color: ${ORANGE_COLOR};
  border-radius: 0px 8px 8px 0px;
  color: white;
  width: 66px;
`

const Input = styled(InputBase)`
  background: white;
  flex: 1;
  border-radius: 8px 0px 0px 8px;
  width: 100%;
`

const SearchGroup = styled(Group)`
  height: 100%;

  @media screen and (max-width: 1500px) {
    gap: 40px;
  }
`

const ListItems = styled(Stack)`
  margin-bottom: 104px;
`

const ScrollAreaRoot = styled(ScrollArea.Root)`
  width: 400px;
`

const TabList = styled(Tabs.List)`
  display: flex;
  /* justify-content: center;
  max-width: 400px;
  margin: 20px auto 40px; */
  gap: 2px;
`

const Tab = styled(Tabs.Trigger)`
  border: none;
  border-radius: 4px 4px 2px 2px;
  border-bottom: 4px solid #fff0e9;
  background: none;
  font-size: ${PX14};
  color: ${DARK_BLUE_COLOR};
  padding: 8px 16px;
  cursor: pointer;
  &[data-state="active"] {
    cursor: default;
    border-bottom: 4px solid ${ORANGE_COLOR};
    background-color: ${LIGHT_ORANGE_COLOR};
  }
`

const placeholderValues = {
  lessen: "Zoek een les of liedje",
  lijsten: "Zoek een lijst",
  themas: "Zoek een thema",
  schoollijsten: "Zoek een schoollijst",
}

const ModalContent = ({
  setIsBulletinAddOpen,
}: {
  setIsBulletinAddOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const [tab, setTab] = useState<TABS>(TABS.LESSONS)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [search, setSearch] = useQueryState("q")
  const currentUser = useCurrentUser()
  const contentRef = useRef<HTMLDivElement | null>(null)

  return (
    <Dialog.Portal forceMount>
      <Overlay
        key="addLessonOverlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
        }}
      />
      <Dialog.Content forceMount>
        <Root
          key="addLessonModal"
          initial={{
            x: -500,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          exit={{
            x: 500,
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }}
          transition={{
            ease: "easeInOut",
            duration: 0.2,
            opacity: { ease: "linear", duration: 0.2 },
          }}
        >
          <Content>
            <Header gap="16px" justify="flex-start">
              <Tabs.Root
                value={tab}
                onValueChange={(value: TABS) => {
                  setTab(value)
                  setSearch("")
                  if (inputRef.current) {
                    inputRef.current.value = ""
                  }
                }}
              >
                <Tabs.List asChild>
                  <TabList>
                    <Tab value={TABS.LESSONS}>
                      <Text bold={tab === TABS.LESSONS}>
                        <Value name="app.bulletinboard.components.bulletinaddmodal.liedles">
                          Lied/les
                        </Value>
                      </Text>
                    </Tab>
                    <Tab value={TABS.PLAYLISTS}>
                      <Text bold={tab === TABS.PLAYLISTS}>
                        <Value name="app.bulletinboard.components.bulletinaddmodal.lijsten">
                          Lijsten
                        </Value>
                      </Text>
                    </Tab>
                    <Tab value={TABS.THEMES}>
                      <Text bold={tab === TABS.THEMES}>
                        <Value name="app.bulletinboard.components.bulletinaddmodal.themas">{`Thema's`}</Value>
                      </Text>
                    </Tab>
                    <Tab value={TABS.LEARNINGLINE}>
                      <Text bold={tab === TABS.LEARNINGLINE}>
                        <Value name="app.bulletinboard.components.bulletinaddmodal.leerlijnen">
                          Leerlijnen
                        </Value>
                      </Text>
                    </Tab>
                    {currentUser?.role !== "ADMIN" && (
                      <Tab value={TABS.SCHOOLLIST}>
                        <Text bold={tab === TABS.SCHOOLLIST}>
                          <Value name="app.bulletinboard.components.bulletinaddmodal.schoollijsten">
                            Schoollijsten
                          </Value>
                        </Text>
                      </Tab>
                    )}
                    <Tab value={TABS.WIDGET}>
                      <Text bold={tab === TABS.WIDGET}>
                        <Value name="app.bulletinboard.components.bulletinaddmodal.themaswidgets">
                          Widgets
                        </Value>
                      </Text>
                    </Tab>
                  </TabList>
                </Tabs.List>
              </Tabs.Root>
              <Dialog.Close asChild>
                <Modal.Close />
              </Dialog.Close>
            </Header>
            {tab !== TABS.LEARNINGLINE &&
              tab !== TABS.THEMES &&
              tab !== TABS.WIDGET && (
                <Search gap="16px">
                  <InputWrapper>
                    <Input
                      autoFocus
                      inputRef={(ref) => {
                        inputRef.current = ref
                        if (ref) {
                          ref.focus({
                            preventScroll: true,
                          })
                        }
                      }}
                      placeholder={placeholderValues[tab]}
                      defaultValue={search || ""}
                      onChange={(e) => {
                        setSearch(e.currentTarget.value)
                      }}
                      right={
                        search ? (
                          <Button
                            onClick={() => {
                              setSearch("")
                              if (inputRef.current) {
                                inputRef.current.value = ""
                              }
                            }}
                          >
                            <TbBackspaceFilled
                              color={`${DARKER_GREY_COLOR}66`}
                              size={35}
                            />
                          </Button>
                        ) : undefined
                      }
                    />
                    <InputButton>
                      <FaSearch size={20} />
                    </InputButton>
                  </InputWrapper>
                  {/* {tab === TABS.LESSONS ? <SearchFilters inputRef={inputRef} /> : null} */}
                  {tab === TABS.PLAYLISTS && <Filters></Filters>}
                </Search>
              )}
            <ScrollArea.Root type="scroll">
              <ScrollArea.Viewport>
                <Suspense>
                  <BulletinAddModalContent
                    selectedTab={tab}
                    inputRef={inputRef}
                  />
                </Suspense>
              </ScrollArea.Viewport>
              <ScrollArea.Scrollbar>
                <ScrollArea.Thumb />
              </ScrollArea.Scrollbar>
            </ScrollArea.Root>
          </Content>
        </Root>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

const BulletinAddModal = () => {
  const [isBulletinAddOpen, setIsBulletinAddOpen] = useAtom(
    addBulletinItemModalOpenAtom
  )
  return (
    <Dialog.Root open={isBulletinAddOpen} onOpenChange={setIsBulletinAddOpen}>
      <AnimatePresence>
        {isBulletinAddOpen && (
          <ModalContent setIsBulletinAddOpen={setIsBulletinAddOpen} />
        )}
      </AnimatePresence>
    </Dialog.Root>
  )
}

export default BulletinAddModal
