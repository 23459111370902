import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import Image from "app/files/components/Image"
import getLessonTypeTitle from "app/lessons/utils/getLessonTypeTitle"
import { SearchElement } from "app/search/types"
import { BulletinBoardItemType } from "db"
import { DARKER_GREY_COLOR } from "theme/consts"
import { PX12 } from "theme/sizes"
import ButtonGroup from "ui/ButtonGroup"
import Loader from "ui/Loader"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import ParticipationIcons from "web/src/lessons/components/ParticipationIcons"
import addBulletinBoardItem from "../mutations/addBulletinBoardItem"
import getBulletinBoardItems, {
  GetBulletinBoardItemsResult,
} from "../queries/getBulletinBoardItems"
import BulletinAddListItem from "./BulletinAddListItem"
import { useMemo } from "react"
import { TParticipantsIcons } from "web/src/learning-lines/types"

const RoundedButton = styled(RoundedButtonBase)`
  font-size: ${PX12};
`

const BulletinAddLessonItem = ({
  lesson,
  bulletinBoardItems,
}: {
  lesson: SearchElement
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [addBulletinBoardItemMutation, addBulletinBoardItemRes] =
    useMutation(addBulletinBoardItem)
  const hasLesson = !!bulletinBoardItems.find(
    (item) =>
      item.type === BulletinBoardItemType.LESSON &&
      item.lesson?.uuid === lesson.uuid
  )

  const participantsIcons = useMemo(() => {
    if (!lesson || !lesson.subjects) return
    const map = new Map<number, TParticipantsIcons>()
    lesson.subjects.forEach((headSubjectGroup) => {
      headSubjectGroup.subjects.forEach((headSubject) => {
        headSubject.participationIcons.forEach((icon) => {
          map.set(icon.id, icon)
        })
      })
    })
    return [...map.values()]
  }, [lesson])

  return (
    <div>
      <BulletinAddListItem
        heading={getLessonTypeTitle(lesson.lessonType)}
        title={lesson.displayTitle}
        description={lesson.searchDescription}
        activeGrades={lesson.grades}
        image={
          <>
            {lesson.cardImage ? (
              <Image
                alt=""
                file={lesson.cardImage}
                key={lesson.uuid}
                width={230}
                height={130}
              />
            ) : null}
          </>
        }
        right={
          <>
            <Stack justify="space-between" gap="8px">
              <Stack gap="8px">
                {participantsIcons && participantsIcons.length > 0 && (
                  <ParticipationIcons lesson={lesson} />
                )}
              </Stack>
              <ButtonGroup>
                <RoundedButton
                  size="small"
                  variant="filled"
                  style={
                    hasLesson
                      ? { opacity: 1, backgroundColor: DARKER_GREY_COLOR }
                      : {}
                  }
                  disabled={hasLesson}
                  onClick={async () => {
                    await addBulletinBoardItemMutation({
                      lessonUuid: lesson.uuid,
                      type: BulletinBoardItemType.LESSON,
                    })
                    await invalidateQuery(getBulletinBoardItems)
                  }}
                >
                  {hasLesson ? (
                    lesson.lessonType === "LESSON" ? (
                      <Value name="app.bulletinboard.components.bulletinaddlessonitem.les_al_op_prikbord">
                        Les al op prikbord
                      </Value>
                    ) : (
                      <Value name="app.bulletinboard.components.bulletinaddlessonitem.lied_al_op_prikbord">
                        Lied al op prikbord
                      </Value>
                    )
                  ) : addBulletinBoardItemRes.isLoading ? (
                    <Loader color="#FFFFFF" />
                  ) : (
                    <Value name="app.bulletinboard.components.bulletinaddlessonitem.zet_op_prikbord">
                      Zet op prikbord
                    </Value>
                  )}
                </RoundedButton>
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )

  // return (
  //   <RootWithBorder>
  //     <Group gap="40px">
  //       {lesson.cardImage && (
  //         <PreviewLink
  //           {...Routes.LessonPage({
  //             lesson: lesson.slug,
  //           })}
  //         >
  //           <LessonPreview
  //             previewId={`search-${lesson.uuid}`}
  //             isPlayVisible={
  //               !!lesson?.previewVideo ||
  //               !!lesson?.previewAudio ||
  //               !!lesson?.rehearse?.video ||
  //               !!lesson?.radioSong
  //             }
  //             image={lesson.cardImage}
  //             previewBegin={
  //               lesson.previewBegin
  //                 ? parseStringTime(lesson.previewBegin)
  //                 : undefined
  //             }
  //             previewEnd={
  //               lesson.previewEnd
  //                 ? parseStringTime(lesson.previewEnd)
  //                 : undefined
  //             }
  //             file={
  //               lesson?.previewVideo ||
  //               lesson?.previewAudio ||
  //               lesson?.rehearse?.video ||
  //               lesson?.radioSong
  //             }
  //           />
  //         </PreviewLink>
  //       )}
  //       <Content>
  //         <Stack gap="8px">
  //           {lesson.displayTitle && (
  //             <Link
  //               {...Routes.LessonPage({
  //                 lesson: lesson.slug,
  //               })}
  //             >
  //               <LessonListItemTitle
  //                 title={lesson.displayTitle}
  //                 contentSubject={lesson.contentSubject}
  //               />
  //             </Link>
  //           )}
  //           <LessonSubTitle size={PX12} lesson={lesson} />
  //           <Group gap="4px" justify="left">
  //             <GradeIcons
  //               allGrades={grades}
  //               activeGrades={lesson.grades}
  //               contentSubjectType={lesson.contentSubject}
  //             />
  //             {lesson.isLearningLineLesson ? (
  //               <LeaningLineLessonLabel
  //                 color={getContentSubjectColor(lesson.contentSubject)}
  //               />
  //             ) : null}
  //           </Group>
  //           <Text size={PX12}>{lesson.searchDescription}</Text>
  //         </Stack>
  //         {/* <Group gap="8px" justify="left">
  //           <LikeLessonButton lesson={lesson} />
  //           <RoundedButton
  //             size="small"
  //             as={Link}
  //             {...Routes.LessonPage({
  //               lesson: lesson.slug,
  //             })}
  //           >
  //             <Value name="apps.web.src.search.components.searchlistitem.bekijk_les">
  //               Bekijk les
  //             </Value>
  //           </RoundedButton>
  //         </Group> */}
  //       </Content>
  //     </Group>
  //     <ParticipationIcons lesson={lesson} />
  //   </RootWithBorder>
  // )
}

export default BulletinAddLessonItem
