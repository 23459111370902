import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import { Value } from "@stringtale/react"
import getContentSubjectColor from "app/content-subjects/getContentSubjectColor"
import Image from "app/files/components/Image"
import { BulletinBoardItemType, ContentSubjectType } from "db"
import { DARKER_GREY_COLOR } from "theme/consts"
import { PX12 } from "theme/sizes"
import ButtonGroup from "ui/ButtonGroup"
import Group from "ui/Group"
import Loader from "ui/Loader"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import addBulletinBoardItem from "../mutations/addBulletinBoardItem"
import { GetBulletinAddLearningLineItemsResult } from "../queries/getBulletinAddLearningLineItems"
import getBulletinBoardItems, {
  GetBulletinBoardItemsResult,
} from "../queries/getBulletinBoardItems"
import BulletinAddListItem from "./BulletinAddListItem"
import { EmptyContainer, EmptyImage } from "./BulletinAddItemPlaceholder"
import CardLabel from "ui/CardLabel"

const BulletinAddLearningLineItem = ({
  contentPage,
  bulletinBoardItems,
}: {
  contentPage: GetBulletinAddLearningLineItemsResult[ContentSubjectType][number]["learningLineThemes"][number]["learningLineContentPages"][number]
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [addBulletinBoardItemMutation, addBulletinBoardItemRes] =
    useMutation(addBulletinBoardItem)

  const hasLearningLine = !!bulletinBoardItems.find(
    (item) =>
      item.type === BulletinBoardItemType.LL_PAGE &&
      item.learningLineId === contentPage.id
  )

  return (
    <div>
      <BulletinAddListItem
        heading={
          <Value name="apps.web.src.bulletinboard.components.bulletinaddlearninglineitem.leerlijn">
            Leerlijn
          </Value>
        }
        title={contentPage.displayTitle}
        image={
          <>
            {contentPage.playlistImage ? (
              <>
                <Image
                  alt=""
                  file={contentPage.playlistImage}
                  key={contentPage.id}
                  width={230}
                  height={130}
                />
                <CardLabel
                  size={PX12}
                  color={getContentSubjectColor(
                    contentPage.learningLineTheme.learningLine.contentSubject
                  )}
                >
                  <Value
                    name="apps.web.src.bulletinboard.components.bulletinaddlearninglineitem.leerlijn_label"
                    format={{
                      cardLabel: contentPage.hasAlternativeGradesLabelBehaviour
                        ? "4S"
                        : contentPage.cardLabel,
                    }}
                  >
                    {`{cardLabel}`}
                  </Value>
                </CardLabel>
              </>
            ) : (
              <EmptyContainer>
                <EmptyImage />
              </EmptyContainer>
            )}
          </>
        }
        right={
          <>
            <Stack justify="space-between">
              <Stack gap="8px">
                {contentPage.period && (
                  <>
                    <Text bold size={PX12}>
                      <Value name="apps.web.src.bulletinboard.components.bulletinaddlearninglineitem.periode">
                        Periode
                      </Value>
                    </Text>
                    <Stack gap="4px">
                      <Group gap="8px" align="center" justify="flex-start">
                        <Text
                          size={PX12}
                          color={getContentSubjectColor(
                            contentPage.learningLineTheme.learningLine
                              .contentSubject,
                            "dark"
                          )}
                          bold
                        >
                          {contentPage.period}
                        </Text>
                      </Group>
                    </Stack>
                  </>
                )}
              </Stack>
              <ButtonGroup>
                <RoundedButton
                  size="small"
                  variant="filled"
                  style={
                    hasLearningLine
                      ? { opacity: 1, backgroundColor: DARKER_GREY_COLOR }
                      : {}
                  }
                  disabled={hasLearningLine}
                  onClick={async () => {
                    await addBulletinBoardItemMutation({
                      learningLineId: contentPage.id,
                      type: BulletinBoardItemType.LL_PAGE,
                    })
                    await invalidateQuery(getBulletinBoardItems)
                  }}
                >
                  {hasLearningLine ? (
                    <Value name="app.bulletinboard.components.bulletinaddlearninglineitem.leerlijn_al_op_prikbord">
                      Leerlijn al op prikbord
                    </Value>
                  ) : addBulletinBoardItemRes.isLoading ? (
                    <Loader color="#FFFFFF" />
                  ) : (
                    <Value name="app.bulletinboard.components.bulletinaddlearninglineitem.zet_op_prikbord">
                      Zet op prikbord
                    </Value>
                  )}
                </RoundedButton>
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )
}

export default BulletinAddLearningLineItem
