import { Value } from "@stringtale/react"
import styled from "@emotion/styled"
import { DARK_BLUE_COLOR, TOASTS_Z_INDEX } from "theme/consts"
import { RADIUS } from "app/playlists/consts"
import { poppins } from "theme/fonts"
import { MODAL_BOX_SHADOW } from "theme/styles"
import { motion } from "framer-motion"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import Text from "ui/Text"
import RoundedButtonBase from "ui/RoundedButton"
import Center from "ui/Center"
import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import addLessonToPlaylist from "app/playlists/mutations/addLessonToPlaylist"
import { Dispatch, SetStateAction } from "react"
import getUserList, {
  GetUserListResult,
} from "app/playlists/queries/getUserList"
import { MAX_KIDS_LIST } from "app/kids/consts"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"

export const Root = styled.div`
  width: 600px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 20px;
  padding: 24px 32px;
  box-sizing: border-box;
  z-index: ${TOASTS_Z_INDEX};
  background-color: ${DARK_BLUE_COLOR};
  color: white;
  border-radius: ${RADIUS};
  font-family: ${poppins.style.fontFamily};
  box-shadow: 2px 4px 10px #00000080;
  align-items: center;
  white-space: nowrap;
`.withComponent(motion.div)

const RoundedButton = styled(RoundedButtonBase)`
  border: 2px solid white;
`

const CloseButton = styled.div`
  cursor: pointer;
  height: 34px;
`

export const Grid = styled.div`
  display: grid;
  gap: 16px;
  align-items: center;
  grid-template-columns: 150px auto auto;
`

const AddLessonSelection = ({
  playlistId,
  userPlaylist,
  selectedLessons,
  setSelectedLessons,
  setIsAddLessonOpen,
}: {
  playlistId: number | null
  userPlaylist: GetUserListResult
  selectedLessons: string[] | null
  setSelectedLessons: Dispatch<SetStateAction<string[] | null>>
  setIsAddLessonOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const [addLessonToPlaylistMutation] = useMutation(addLessonToPlaylist)

  const maxAmountReached =
    userPlaylist &&
    userPlaylist.playlist &&
    userPlaylist.accessLevel === "KIDS" &&
    selectedLessons?.length &&
    selectedLessons?.length + userPlaylist.playlist.lessons.length >=
      MAX_KIDS_LIST

  return (
    <Root
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.2,
      }}
    >
      <Grid>
        <Text>
          <Value
            name="apps.web.src.playlists.components.addlessonselection.geselecteerd"
            format={{ count: selectedLessons?.length }}
          >
            {`{count} geselecteerd`}
          </Value>
        </Text>
        <RoundedButton
          disabled={!!maxAmountReached}
          onClick={async () => {
            // Add lessons to playlist based on lesson uuid
            await addLessonToPlaylistMutation({
              playlistIds: [playlistId],
              lessonUuids: selectedLessons,
            })
            await invalidateQuery(getUserList)
            await invalidateQuery(getBulletinBoardItems)
            await setIsAddLessonOpen(false)
          }}
          variant="filled"
          color="blue"
        >
          {maxAmountReached ? (
            <Value name="apps.web.src.playlists.components.addlessonselection.maximum_aantal_lessen_bereikt">
              Maximum aantal lessen bereikt
            </Value>
          ) : (
            <Value name="apps.web.src.playlists.components.addlessonselection.voeg_selectie_toe_aan_afspeellijst">
              Voeg selectie toe aan afspeellijst
            </Value>
          )}
        </RoundedButton>
        <CloseButton onClick={() => setSelectedLessons([])}>
          <Center>
            <FaTimes />
          </Center>
        </CloseButton>
      </Grid>
    </Root>
  )
}

export default AddLessonSelection
