import { useQuery } from "@blitzjs/rpc"
import { Value } from "@stringtale/react"
import getOrganizatorRole from "app/auth/queries/getOrganizatorRole"
import getCurrentGroup from "app/users/queries/getCurrentGroup"
import getUserGroups from "app/users/queries/getUserGroups"
import { PX14 } from "theme/sizes"
import Radio from "ui/Radio"
import Stack from "ui/Stack"
import Text from "ui/Text"
import { Form } from "utils/form/createForm"
import { ZodObject } from "zod"
import getListTypeCanAdd from "../utils/getListTypeCanAdd"

export default function PlaylistAccessLevels<T extends ZodObject<any>>({
  form,
  accessLevel,
  onValueChange,
}: {
  form: Form<T>
  accessLevel: string
  onValueChange: (accessLevel: string) => void
}) {
  const [role] = useQuery(getOrganizatorRole, undefined)
  const [group] = useQuery(getCurrentGroup, undefined)
  const [userGroups] = useQuery(getUserGroups, undefined)
  if (!group) return null

  return (
    <form.Field name="accessLevel">
      <Stack gap="12px">
        <Stack gap="4px">
          <Text bold size={PX14}>
            <Value
              name="apps.web.src.playlists.components.playlistaccesslevels.title"
              version="1"
            >
              Zichtbaar als
            </Value>
          </Text>
        </Stack>
        <form.Control>
          <Radio.Group value={accessLevel} onValueChange={onValueChange}>
            <Radio
              label={
                <Value name="apps.web.src.playlists.components.playlistaccesslevels.groepslijst_voor_mijn_groep">
                  Groepslijst voor mijn groep
                </Value>
              }
              value="CURRENT_GROUP"
            />
            <Radio
              label={
                <Value name="apps.web.src.playlists.components.playlistaccesslevels.privelijst_voor_mijzelf_alleen">
                  Privelijst voor mijzelf alleen
                </Value>
              }
              value="PERSONAL"
            />
            {userGroups && userGroups.length > 1 && (
              <Radio
                label={
                  <Value name="apps.web.src.playlists.components.playlistaccesslevels.groepslijst_voor_meerdere_groepen">
                    Groepslijst voor meerdere groepen
                  </Value>
                }
                value="SELECTED_GROUP"
                disabled={!group}
              />
            )}
            {role && getListTypeCanAdd("SCHOOL", role) && (
              <Radio
                label={
                  <Value name="apps.web.src.playlists.components.playlistaccesslevels.schoollijst_voor_meedere_leerjaren">
                    Schoollijst voor meerdere leerjaren
                  </Value>
                }
                value="SCHOOL"
              />
            )}
          </Radio.Group>
        </form.Control>
        <form.Message />
      </Stack>
    </form.Field>
  )
}
