import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { Value } from "@stringtale/react"
import Avatar from "app/files/components/Avatar"
import Image from "app/files/components/Image"
import { GetAllGradesResult } from "app/grades/queries/getAllGrades"
import { BulletinBoardItemType } from "db"
import { useMemo } from "react"
import { DARKER_GREY_COLOR, PX12 } from "theme/consts"
import ButtonGroup from "ui/ButtonGroup"
import Group from "ui/Group"
import RoundedButtonBase from "ui/RoundedButton"
import Stack from "ui/Stack"
import Text from "ui/Text"
import addBulletinBoardItem from "../mutations/addBulletinBoardItem"
import { GetBulletinAddSchoollistItemsResult } from "../queries/getBulletinAddSchoollistItems"
import getBulletinBoardItems, {
  GetBulletinBoardItemsResult,
} from "../queries/getBulletinBoardItems"
import BulletinAddListItem from "./BulletinAddListItem"
import Loader from "ui/Loader"
import { EmptyContainer, EmptyImage } from "./BulletinAddItemPlaceholder"

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  border-radius: 8px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
  width: 100%;
  height: 100%;
`

const RoundedButton = styled(RoundedButtonBase)`
  font-size: ${PX12};
`

const BulletinAddSchoollistItem = ({
  list,
  bulletinBoardItems,
}: {
  list: GetBulletinAddSchoollistItemsResult[number]
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [addBulletinBoardItemMutation, addBulletinBoardItemRes] =
    useMutation(addBulletinBoardItem)

  const activeGrades = useMemo(() => {
    const grades: GetAllGradesResult = []
    list.playlist?.lessons.map((lesson) =>
      lesson.lesson.grades.map((grade) =>
        grades.includes(grade) ? null : grades.push(grade)
      )
    )
    return grades
  }, [list])

  const hasSchoollist = !!bulletinBoardItems.find(
    (item) =>
      item.type === BulletinBoardItemType.SCHOOL_LIST &&
      item.playlistId === list.id
  )

  return (
    <div>
      <BulletinAddListItem
        heading={
          <Value name="apps.web.src.bulletinboard.components.bulletinaddschoollistitem.schoollijst">
            Schoollijst
          </Value>
        }
        title={list.playlist?.displayTitle}
        description={list.playlist?.description}
        activeGrades={activeGrades}
        image={
          <>
            {list.playlist?.lessons ? (
              <ImageGrid>
                {list.playlist?.lessons.map((lesson) =>
                  lesson.lesson.cardImage ? (
                    <Image
                      alt=""
                      file={lesson.lesson.cardImage}
                      key={lesson.lesson.id}
                      width={115}
                      height={65}
                    />
                  ) : (
                    <EmptyContainer>
                      <EmptyImage />
                    </EmptyContainer>
                  )
                )}
                {list.playlist?.lessons &&
                  [...Array(4 - list.playlist?.lessons.length)].map(
                    function () {
                      return (
                        <EmptyContainer>
                          <EmptyImage />
                        </EmptyContainer>
                      )
                    }
                  )}
              </ImageGrid>
            ) : null}
          </>
        }
        right={
          <>
            <Stack justify="space-between">
              <Stack gap="8px">
                {list.owner && (
                  <>
                    <Text bold size={PX12}>
                      <Value name="apps.web.src.bulletinboard.components.bulletinaddschoollistitem.gemaakt_door">
                        Gemaakt door
                      </Value>
                    </Text>
                    <Stack gap="4px">
                      <Group gap="8px" align="center" justify="flex-start">
                        <Avatar user={list.owner} width={20} height={20} />
                        <Text size={PX12}>{list.owner.firstName}</Text>
                      </Group>
                    </Stack>
                  </>
                )}
              </Stack>
              <ButtonGroup>
                <RoundedButton
                  size="small"
                  variant="filled"
                  style={
                    hasSchoollist
                      ? { opacity: 1, backgroundColor: DARKER_GREY_COLOR }
                      : {}
                  }
                  disabled={hasSchoollist}
                  onClick={async () => {
                    await addBulletinBoardItemMutation({
                      playlistId: list.id,
                      type: BulletinBoardItemType.SCHOOL_LIST,
                    })
                    await invalidateQuery(getBulletinBoardItems)
                  }}
                >
                  {hasSchoollist ? (
                    <Value name="app.bulletinboard.components.bulletinaddschoollistitem.schoollijst_al_op_prikbord">
                      Schoollijst al op prikbord
                    </Value>
                  ) : addBulletinBoardItemRes.isLoading ? (
                    <Loader color="#FFFFFF" />
                  ) : (
                    <Value name="app.bulletinboard.components.bulletinaddthemeitem.zet_op_prikbord">
                      Zet op prikbord
                    </Value>
                  )}
                </RoundedButton>
              </ButtonGroup>
            </Stack>
          </>
        }
      />
    </div>
  )
}

export default BulletinAddSchoollistItem
