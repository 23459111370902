import { invalidateQuery, useMutation, useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value, isInsideToolbar } from "@stringtale/react"
import getBulletinBoardItems from "app/bulletinboard/queries/getBulletinBoardItems"
import { useCurrentOrganization } from "app/core/hooks/useCurrentOrganization"
import { parseAsBoolean, parseAsInteger, useQueryState } from "nuqs"
import getAllGrades from "app/grades/queries/getAllGrades"
import AddLessonOverlay from "app/playlists/components/AddLessonOverlay"
import MigratePlaylistToGroupsModal from "app/playlists/components/MigratePlaylistToGroupsModal"
import NewSchoolListModal from "app/schoolbeheer/components/schoollists/NewSchoolListModal"
import updateSchoolList from "app/schoolbeheer/mutations/updateSchoolList"
import getSchoolLists from "app/schoolbeheer/queries/getSchoolLists"
import useToast from "app/toasts/hooks/useToast"
import { AnimatePresence, motion } from "framer-motion"
import { useAtom } from "jotai"
import { Suspense, useMemo, useState } from "react"
import {
  DARK_BLUE_COLOR,
  PLAYLIST_MODAL_OVERLAY_Z_INDEX,
  PLAYLIST_MODAL_Z_INDEX,
} from "theme/consts"
import { poppins } from "theme/fonts"
import { MODAL_BOX_SHADOW } from "theme/styles"
import ConfirmModal from "ui/ConfirmModal"
import * as Modal from "ui/Modal"
import {
  MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE,
  MODAL_QUERY_KEY,
  MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE,
  MODAL_QUERY_SHARE_TYPE_VALUE,
  RADIUS,
} from "../consts"
import getGroupedUserPlaylists from "../queries/getGroupedUserPlaylists"
import getUserList from "../queries/getUserList"
import { publishUserListIdAtom, unpublishUserListIdAtom } from "../state"
import { BulletinBoardModalContent } from "./BulletinBoardModalContent"
import ManageSchoollistContent from "./ManageSchoollistContent"
import NewPlaylistModal from "./NewPlaylistModal"
import { PlaylistModalContent } from "./PlaylistModalContent"
import UserListModalSideBar from "./PlaylistModalSideBar"
import PlaylistModalShareContent from "./ShareContent"

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: ${PLAYLIST_MODAL_OVERLAY_Z_INDEX};
`.withComponent(motion.div)

const Root = styled.div`
  /* width: calc(100vw - 20px); */
  /* height: calc(100vh - 20px); */
  position: fixed;
  top: 25px;
  left: 25px;
  bottom: 25px;
  right: 25px;
  /* transform: translate(-50%, -50%); */
  z-index: ${PLAYLIST_MODAL_Z_INDEX};

  background-color: white;
  border-radius: ${RADIUS};
  font-family: ${poppins.style.fontFamily};
  /* overflow: hidden; */
  display: flex;
  overflow: hidden;
  overflow: clip;
  ${MODAL_BOX_SHADOW}
`.withComponent(motion.div)

const Right = styled.div`
  background-color: white;
  flex: 1;
  /* border-bottom-right-radius: ${RADIUS};
  border-top-right-radius: ${RADIUS}; */
  display: flex;
  flex-direction: column;
`

const ModalClose = styled(Modal.Close)`
  border: 2px solid #ffffff;
  background-color: ${DARK_BLUE_COLOR};
  color: white;
`

const PlaylistModal = () => {
  const addToast = useToast()
  const currentOrg = useCurrentOrganization()
  const [playlists] = useQuery(getGroupedUserPlaylists, undefined, {
    staleTime: 30000,
  })
  const [grades] = useQuery(getAllGrades, undefined)
  const [isNewPlaylistOpen, setIsNewPlaylistOpen] = useState(false)
  const [isAddLessonOpen, setIsAddLessonOpen] = useState(false)

  const [publishUserListId, setPublishUserListId] = useAtom(
    publishUserListIdAtom
  )
  const [unpublishUserListId, setUnpublishUserListId] = useAtom(
    unpublishUserListIdAtom
  )
  const [isNewSchoolListModalOpen, setNewSchoolListModalOpen] =
    useState<boolean>(false)
  const [queryParam, setOpen] = useQueryState(MODAL_QUERY_KEY)
  const [openSchoolList, setOpenSchoolList] = useQueryState(
    MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE,
    parseAsInteger
  )
  const [isMigrationModalVisibe, setMigrationModalVisibe] = useState(false)

  const [updateSchoolListMutation] = useMutation(updateSchoolList)

  const open = queryParam !== null
  const playlistId = useMemo(() => {
    if (queryParam === "gedeeld") {
      return null
    }
    if (queryParam === "kids" && playlists.kidsPlaylist?.id) {
      return playlists.kidsPlaylist.id
    }
    const paramVal = queryParam ? parseInt(queryParam) : undefined
    if (Number.isNaN(paramVal)) {
      return null
    }
    if (
      paramVal &&
      (playlists.otherPlaylists.some((p) => p.id === paramVal) ||
        playlists.schoolPlaylists.some((p) => p.id === paramVal) ||
        playlists.kidsPlaylist?.id === paramVal)
    ) {
      return paramVal
    }
    return playlists.otherPlaylists[0]?.id || null
  }, [
    playlists.kidsPlaylist?.id,
    playlists.otherPlaylists,
    playlists.schoolPlaylists,
    queryParam,
  ])

  return (
    <>
      <Dialog.Root
        open={open}
        onOpenChange={(val) => {
          setOpen(val ? "" : null)
          invalidateQuery(getBulletinBoardItems)
        }}
        modal
      >
        <AnimatePresence>
          {open && (
            <>
              <Dialog.Portal forceMount>
                <Dialog.Content
                  forceMount
                  onPointerDownOutside={(e) => {
                    if (!e.target) return
                    if (!(e.target instanceof Element)) return
                    if (isInsideToolbar(e)) e.preventDefault()
                    const radioElement = document.querySelector("#radiobox")
                    const radioVolumeElement =
                      document.querySelector("#radioboxVolume")
                    if (radioElement && radioElement.contains(e.target)) {
                      e.preventDefault()
                    }
                    if (
                      radioVolumeElement &&
                      radioVolumeElement.contains(e.target)
                    ) {
                      e.preventDefault()
                    }
                  }}
                >
                  <Root
                    initial={{ y: -1000, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -1000, opacity: 0 }}
                    transition={{
                      ease: "backOut",
                      duration: 0.6,
                    }}
                  >
                    <UserListModalSideBar
                      {...{
                        playlists,
                        playlistId,
                        setIsNewPlaylistOpen,
                      }}
                    />
                    <Right>
                      <Suspense>
                        {queryParam ===
                        MODAL_QUERY_BULLETIN_BOARD_TYPE_VALUE ? (
                          <BulletinBoardModalContent />
                        ) : queryParam === MODAL_QUERY_SHARE_TYPE_VALUE ? (
                          <PlaylistModalShareContent grades={grades} />
                        ) : queryParam ===
                          MODAL_QUERY_MANAGE_SCHOOLLIST_TYPE_VALUE ? (
                          <ManageSchoollistContent
                            grades={grades}
                            setNewSchoolListModalOpen={
                              setNewSchoolListModalOpen
                            }
                            setIsAddLessonOpen={setIsAddLessonOpen}
                            setIsNewPlaylistOpen={setIsNewPlaylistOpen}
                            setMigrationModalVisibe={setMigrationModalVisibe}
                          />
                        ) : (
                          playlistId && (
                            <PlaylistModalContent
                              id={playlistId}
                              key={playlistId}
                              grades={grades}
                              onClose={() => {
                                setOpen(null)
                              }}
                              setIsNewPlaylistOpen={setIsNewPlaylistOpen}
                              setIsAddLessonOpen={setIsAddLessonOpen}
                              setMigrationModalVisibe={setMigrationModalVisibe}
                            />
                          )
                        )}
                      </Suspense>
                    </Right>
                    <Dialog.Close asChild>
                      <ModalClose>
                        <FaTimes />
                      </ModalClose>
                    </Dialog.Close>
                  </Root>
                </Dialog.Content>
                <Overlay
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              </Dialog.Portal>
            </>
          )}
        </AnimatePresence>
      </Dialog.Root>
      <Dialog.Root open={isNewPlaylistOpen} onOpenChange={setIsNewPlaylistOpen}>
        {isNewPlaylistOpen && (
          <NewPlaylistModal
            onClose={() => {
              setIsNewPlaylistOpen(false)
            }}
          />
        )}
      </Dialog.Root>
      <Dialog.Root open={isAddLessonOpen} onOpenChange={setIsAddLessonOpen}>
        <AnimatePresence>
          {isAddLessonOpen && (openSchoolList || playlistId) && (
            <AddLessonOverlay
              setIsAddLessonOpen={setIsAddLessonOpen}
              playlistId={(openSchoolList || playlistId)!}
            />
          )}
        </AnimatePresence>
      </Dialog.Root>
      <Dialog.Root
        open={isMigrationModalVisibe}
        onOpenChange={setMigrationModalVisibe}
      >
        {isMigrationModalVisibe && playlistId && (
          <MigratePlaylistToGroupsModal
            key={playlistId}
            playlistId={playlistId}
            onClose={() => setMigrationModalVisibe(false)}
          />
        )}
      </Dialog.Root>
      {!!unpublishUserListId && (
        <Dialog.Root
          open={!!unpublishUserListId}
          onOpenChange={() => setUnpublishUserListId(null)}
        >
          {!!unpublishUserListId && (
            <ConfirmModal
              title={
                <Value
                  name="apps.web.src.playlists.components.playlistmodal.lijst_onzichtbaar_maken_weet_je_het_zeker"
                  version="1"
                >
                  {
                    /* HTML */ `Weet je zeker dat je deze lijst onzichtbaar wilt
                    maken voor collega's?`
                  }
                </Value>
              }
              description={
                <Value
                  name="apps.web.src.playlists.components.playlistmodal.een_schoollijst_onzichtbaar_maken_voor"
                  version="1"
                  format={{
                    orgName: currentOrg?.displayTitle,
                  }}
                >
                  {
                    /* HTML */ `Deze schoollijst wordt onzichtbaar voor alle
                    collega's van {orgName}. Zij zien deze lijst dan niet (meer)
                    in hun bibliotheek. Ook wordt deze verwijderd van alle
                    prikborden.`
                  }
                </Value>
              }
              confirmTitle={
                <Value name="apps.web.src.playlists.components.playlistmodal.bevestig">
                  Bevestig
                </Value>
              }
              declineTitle={
                <Value name="apps.web.src.playlists.components.playlistmodal.bevestig">
                  Annuleren
                </Value>
              }
              onConfirm={async () => {
                await updateSchoolListMutation({
                  id: unpublishUserListId,
                  status: "DRAFT",
                })
                await invalidateQuery(getUserList)
                await invalidateQuery(getSchoolLists)
                await invalidateQuery(getGroupedUserPlaylists)
                await invalidateQuery(getBulletinBoardItems)
                addToast({
                  content: (
                    <>
                      <Value name="apps.web.src.playlists.components.unpublishschoollistmodal.schoollijst_is_onzichtbaar_gezet">
                        Schoollijst is onzichtbaar gezet
                      </Value>
                    </>
                  ),
                })
                setUnpublishUserListId(null)
              }}
              onDecline={() => {
                setUnpublishUserListId(null)
              }}
            />
          )}
        </Dialog.Root>
      )}
      {!!publishUserListId && (
        <Dialog.Root
          open={!!publishUserListId}
          onOpenChange={() => setPublishUserListId(null)}
        >
          {!!publishUserListId && (
            <ConfirmModal
              title={
                <Value
                  name="apps.web.src.playlists.components.playlistmodal.lijst_zichtbaar_maken_weet_je_het_zeker"
                  version="1"
                >
                  Weet je zeker dat je deze lijst zichtbaar wil maken?
                </Value>
              }
              description={
                <Value
                  format={{
                    orgName: currentOrg?.displayTitle,
                  }}
                  name="apps.web.src.playlists.components.playlistmodal.deze_schoollijst_is_nu_zichtbaar_voor_alle_collega_s_van_naam_organisatie_zij_zien_deze_afspeellijst_vanaf_nu_in_hun_bibliotheek"
                  version="1"
                >
                  {
                    /* HTML */ `Deze schoollijst wordt zichtbaar voor alle
                    collega's van {orgName} van de groepen waarvoor je deze
                    lijst hebt gemaakt. Zij zien deze lijst vanaf nu in hun
                    bibliotheek.`
                  }
                </Value>
              }
              confirmTitle={
                <Value name="apps.web.src.playlists.components.playlistmodal.bevestig">
                  Bevestig
                </Value>
              }
              declineTitle={
                <Value name="apps.web.src.playlists.components.playlistmodal.bevestig">
                  Annuleren
                </Value>
              }
              onConfirm={async () => {
                await updateSchoolListMutation({
                  id: publishUserListId,
                  status: "PUBLISHED",
                })
                await invalidateQuery(getSchoolLists)
                await invalidateQuery(getUserList)
                await invalidateQuery(getGroupedUserPlaylists)
                addToast({
                  content: (
                    <>
                      <Value name="apps.web.src.playlists.components.publishedschoollistmodal.schoollijst_is_zichtbaar_gezet">
                        Schoollijst is zichtbaar gezet
                      </Value>
                    </>
                  ),
                })
                setPublishUserListId(null)
              }}
              onDecline={() => {
                setPublishUserListId(null)
              }}
            />
          )}
        </Dialog.Root>
      )}
      <Dialog.Root
        open={isNewSchoolListModalOpen}
        onOpenChange={setNewSchoolListModalOpen}
      >
        <NewSchoolListModal onClose={() => setNewSchoolListModalOpen(false)} />
      </Dialog.Root>
    </>
  )
}

export default PlaylistModal
