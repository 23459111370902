import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { useIntersection } from "@mantine/hooks"
import { FaSlidersH } from "@react-icons/all-files/fa/FaSlidersH"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import FilterButtons from "app/bulletinboard/components/FilterButtons"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import { parseAsBoolean, useQueryState } from "nuqs"
import getUserPlaylistTitle from "app/playlists/utils/getUserPlaylistTitle"
import SearchFilters from "app/search/components/SearchFilters"
import SearchProvider, {
  useSearchContext,
} from "app/search/components/SearchProvider"
import { AnimatePresence } from "framer-motion"
import { useSetAtom } from "jotai"
import { MutableRefObject, Suspense, useEffect, useMemo, useState } from "react"
import Center from "ui/Center"
import Loader from "ui/Loader"
import RoundedButton from "ui/RoundedButton"
import { Separator } from "ui/Separator"
import Stack from "ui/Stack"
import Text from "ui/Text"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerIconClose,
  DrawerOverlay,
  DrawerPortal,
  DrawerTrigger,
} from "../../core/components/Drawer"
import { TABS } from "../consts"
import getBulletinAddSchoollistItems from "../queries/getBulletinAddSchoollistItems"
import getBulletinBoardItems, {
  GetBulletinBoardItemsResult,
} from "../queries/getBulletinBoardItems"
import getBulletinLibraryItems, {
  GetBulletinLibraryItemsResult,
} from "../queries/getBulletinLibraryItems"
import { addBulletinItemModalOpenAtom } from "../state"
import BulletinAddGroupListItem from "./BulletinAddGroupListItem"
import BulletinAddLearningLineItem from "./BulletinAddLearningLineItem"
import BulletinAddLearningLineTab from "./BulletinAddLearningLineTab"
import BulletinAddLessonItem from "./BulletinAddLessonItem"
import BulletinAddModalKidsItem from "./BulletinAddModalKidsItem"
import BulletinAddModalWidgetItem from "./BulletinAddModalWidgetItem"
import BulletinAddSchoollistItem from "./BulletinAddSchoollistItem"
import BulletinAddThemeItem from "./BulletinAddThemeItem"
import BulletinAddThemeTab from "./BulletinAddThemeTab"

const FilterButton = styled(RoundedButton)`
  border-radius: 8px;
  height: 46px;
  /* padding-left: 11px; */
`

const ListItems = styled(Stack)`
  margin-bottom: 200px;
`

const EmptyStateContainer = styled(Stack)`
  margin: 16px 0;
`

const basicSearchFilter = ({
  title,
  searchValue,
}: {
  title: string
  searchValue: string
}) => {
  return title.toLowerCase().includes(searchValue.toLowerCase().trim())
}

const EmptyState = () => {
  return (
    <>
      <Separator />
      <EmptyStateContainer>
        <Text>
          <Value
            name="apps.web.src.bulletinboard.components.bulletinaddmodalcontent.geen_resultaten_gevonden_voor_uw_zoekopdracht"
            version="1"
          >
            Geen resultaten.
          </Value>
        </Text>
      </EmptyStateContainer>
    </>
  )
}

const TabContent = ({
  children,
  count,
}: {
  children?: React.ReactNode
  count?: number
}) => {
  if (!children || count === 0) {
    return <EmptyState />
  }
  return (
    <ListItems>
      <Stack gap="16px">
        {count && (
          <Text>
            <Value
              name="apps.web.src.bulletinboard.components.bulletinaddmodalcontent.items"
              format={{ count: count }}
            >
              {`{count} items`}
            </Value>
          </Text>
        )}
        <Separator />
      </Stack>
      <AnimatePresence>{children}</AnimatePresence>
    </ListItems>
  )
}

const LessonsTab = ({
  bulletinBoardItems,
}: {
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const { hits, next, isEnd } = useSearchContext()
  const { ref, entry } = useIntersection({
    threshold: 1,
  })

  useEffect(() => {
    if (entry?.isIntersecting) {
      next()
    }
  }, [entry?.isIntersecting, next])

  return (
    <TabContent count={hits?.pages[0].totalHits}>
      {hits &&
        hits.pages.map((h) =>
          h?.hits.map((hit) => (
            <span key={hit.uuid}>
              <BulletinAddLessonItem
                lesson={hit}
                bulletinBoardItems={bulletinBoardItems}
              />
              <Separator />
            </span>
          ))
        )}
      {isEnd ? null : <div ref={ref}></div>}
    </TabContent>
  )
}
export enum AllowedBulletinGroupListTypes {
  PERSONAL_LISTS = "Mijn lijsten",
  GROUP_LISTS = "Groepslijsten",
  THEME_LISTS = "Themalijsten",
  LEARNING_LINE_LISTS = "Leerlijnlijsten",
}

const ListTab = ({
  search,
  bulletinBoardItems,
}: {
  search: string | null
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [filteredTypes, setFilteredTypes] = useState<
    AllowedBulletinGroupListTypes[]
  >([])
  const [libraryPlaylists] = useQuery(getBulletinLibraryItems, undefined)

  const filteredContent = useMemo(() => {
    const filterUserListByAllowedType = (
      list: GetBulletinLibraryItemsResult[number]
    ) => {
      switch (list.type) {
        case "PLAYLIST":
          if (list.accessLevel === "GROUP")
            return filteredTypes.includes(
              AllowedBulletinGroupListTypes.GROUP_LISTS
            )
          if (list.accessLevel === "PERSONAL")
            return filteredTypes.includes(
              AllowedBulletinGroupListTypes.PERSONAL_LISTS
            )
          break
        case "THEME":
          return filteredTypes.includes(
            AllowedBulletinGroupListTypes.THEME_LISTS
          )
        case "LL":
          return filteredTypes.includes(
            AllowedBulletinGroupListTypes.LEARNING_LINE_LISTS
          )
      }
    }

    return libraryPlaylists?.filter((list) => {
      return (
        (filteredTypes.length === 0 || filterUserListByAllowedType(list)) &&
        (!search ||
          basicSearchFilter({
            title:
              list.type === "LL" && list.learningLine
                ? list.learningLine.displayTitle
                : getUserPlaylistTitle(list),
            searchValue: search,
          }))
      )
    })
  }, [filteredTypes, libraryPlaylists, search])

  const listItem = (userPlaylist: GetBulletinLibraryItemsResult[number]) => {
    switch (userPlaylist.type) {
      case "PLAYLIST": {
        return (
          <BulletinAddGroupListItem
            groupPlaylist={userPlaylist}
            bulletinBoardItems={bulletinBoardItems}
          />
        )
        /* if (userPlaylist.accessLevel === "GROUP") {
          return (
            <BulletinAddGroupListItem
              groupPlaylist={userPlaylist}
              bulletinBoardItems={bulletinBoardItems}
            />
          )
        } else if (userPlaylist.accessLevel === "PERSONAL") {
          return (
            <BulletinAddPersonalListItem
              userPlaylist={userPlaylist}
              bulletinBoardItems={bulletinBoardItems}
            />
          )
        }
        return null */
      }
      case "THEME": {
        if (
          userPlaylist.themeGroup &&
          userPlaylist.themeGroup.theme?.listImage
        ) {
          return (
            <BulletinAddThemeItem
              themeGroup={userPlaylist.themeGroup}
              selectedTheme={userPlaylist.themeGroup.theme}
              bulletinBoardItems={bulletinBoardItems}
            />
          )
        }
      }
      case "LL": {
        if (userPlaylist.type === "LL" && userPlaylist.learningLine) {
          return (
            <BulletinAddLearningLineItem
              contentPage={userPlaylist.learningLine}
              bulletinBoardItems={bulletinBoardItems}
            />
          )
        }
      }
    }
  }

  return (
    <Stack gap="16px">
      <FilterButtons
        {...{
          filteredTypes,
          setFilteredTypes,
        }}
      />
      <TabContent count={filteredContent?.length}>
        {filteredContent.map((element) => (
          <span key={element.id}>
            {listItem(element)}
            <Separator />
          </span>
        ))}
      </TabContent>
    </Stack>
  )
}

const SchoolListTab = ({
  search,
  bulletinBoardItems,
}: {
  search: string | null
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const [schoolPlaylists] = useQuery(getBulletinAddSchoollistItems, undefined)
  const filteredLists = useMemo(() => {
    if (!schoolPlaylists) return null
    return !search || search === ""
      ? schoolPlaylists
      : schoolPlaylists?.filter(
          (list) =>
            list.playlist &&
            basicSearchFilter({
              title: list.playlist.displayTitle,
              searchValue: search,
            })
        )
  }, [schoolPlaylists, search])

  return (
    <TabContent count={filteredLists?.length}>
      {filteredLists &&
        filteredLists.map((list) => (
          <span key={list.id}>
            <BulletinAddSchoollistItem
              list={list}
              bulletinBoardItems={bulletinBoardItems}
            />
            <Separator />
          </span>
        ))}
    </TabContent>
  )
}

const WidgetTab = ({
  bulletinBoardItems,
}: {
  bulletinBoardItems: GetBulletinBoardItemsResult
}) => {
  const group = useCurrentGroup()
  return (
    <TabContent count={1}>
      <BulletinAddModalWidgetItem bulletinBoardItems={bulletinBoardItems} />
      {group && (
        <BulletinAddModalKidsItem bulletinBoardItems={bulletinBoardItems} />
      )}
    </TabContent>
  )
}

const BulletinAddModalContent = ({
  selectedTab,
  inputRef,
}: {
  selectedTab: TABS
  inputRef: MutableRefObject<HTMLInputElement | null>
}) => {
  const [bulletinBoardItems] = useQuery(getBulletinBoardItems, { all: true })
  const setIsBulletinModalOpen = useSetAtom(addBulletinItemModalOpenAtom)
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [search, setSearch] = useQueryState("q")

  switch (selectedTab) {
    case TABS.LESSONS: {
      return (
        <Suspense
          fallback={
            <Center>
              <Loader />
            </Center>
          }
        >
          <SearchProvider search={search}>
            <Stack gap="16px">
              <div>
                <Drawer>
                  <DrawerTrigger asChild>
                    <FilterButton left={<FaSlidersH />} variant="filled">
                      <Value name="apps.web.src.bulletinboard.components.bulletinaddmodalcontent.filter" version="6"
                      >
                        Filter
                      </Value>
                    </FilterButton>
                  </DrawerTrigger>
                  <DrawerPortal>
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerHeader>
                        <DrawerIconClose>
                          <FaTimes size={20} />
                        </DrawerIconClose>
                      </DrawerHeader>
                      <DrawerDescription>
                        <Stack gap="12px">
                          <Text bold>
                            <Value name="apps.web.src.studio.components.studiothemegroup.filter">
                              Filter
                            </Value>
                          </Text>
                          <Separator />
                          <SearchFilters inputRef={inputRef} />
                        </Stack>
                      </DrawerDescription>
                      <DrawerFooter>
                        <DrawerClose asChild>
                          <RoundedButton variant="filled">
                            <Value name="apps.web.src.studio.components.studiothemegroup.filter_resultaten">
                              Filter resultaten
                            </Value>
                          </RoundedButton>
                        </DrawerClose>
                      </DrawerFooter>
                    </DrawerContent>
                  </DrawerPortal>
                </Drawer>
              </div>
              <LessonsTab bulletinBoardItems={bulletinBoardItems} />
            </Stack>
          </SearchProvider>
        </Suspense>
      )
    }
    case TABS.PLAYLISTS: {
      return (
        <Suspense
          fallback={
            <Center>
              <Loader />
            </Center>
          }
        >
          <ListTab search={search} bulletinBoardItems={bulletinBoardItems} />
        </Suspense>
      )
    }
    case TABS.SCHOOLLIST: {
      return (
        <Suspense
          fallback={
            <Center>
              <Loader />
            </Center>
          }
        >
          <SchoolListTab
            search={search}
            bulletinBoardItems={bulletinBoardItems}
          />
        </Suspense>
      )
    }
    case TABS.THEMES: {
      return <BulletinAddThemeTab bulletinBoardItems={bulletinBoardItems} />
      /* return (
        <Suspense
          fallback={
            <Center>
              <Loader />
            </Center>
          }
        >
          <ThemeTab search={search} bulletinBoardItems={bulletinBoardItems} />
        </Suspense>
      ) */
    }
    case TABS.LEARNINGLINE: {
      return (
        <BulletinAddLearningLineTab bulletinBoardItems={bulletinBoardItems} />
      )
    }
    case TABS.WIDGET: {
      return <WidgetTab bulletinBoardItems={bulletinBoardItems} />
    }
    default: {
      return null
    }
  }
}

export default BulletinAddModalContent
