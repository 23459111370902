import { T } from "@blitzjs/auth/dist/index-039b36a5"
import { usePathname, useSearchParams } from "next/navigation"
import { useEffect, useRef, useState } from "react"

import generateAnalyticsObject, {
  AnalyticsObject,
} from "app/analytics/utils/generateAnalyticsObject"

function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

interface NavigationEvents {
  routeChanged?: ({
    pathname,
    searchParams,
  }: {
    pathname: string | null
    searchParams: URLSearchParams | null
  }) => void
}

const useNavigation = ({ on }: { on?: NavigationEvents }) => {
  const pathname = usePathname()
  const prevPathname = usePrevious(pathname)

  const searchParams = useSearchParams()
  const prevSearchParams = usePrevious(searchParams)

  const { routeChanged } = on || {}
  const [route, setRoute] = useState({ pathname, searchParams })

  useEffect(() => {
    if (
      // Don't do anything on searchParam changes for now
      // searchParams?.toString() !== prevSearchParams?.toString() ||
      pathname !== prevPathname
    ) {
      setRoute({ pathname, searchParams })
      routeChanged?.({ pathname, searchParams })
      // console.log(
      //   "route changed, logging changes",
      //   searchParams?.toString(),
      //   prevSearchParams?.toString(),
      //   pathname,
      //   prevPathname
      // )
    }
  }, [pathname, prevPathname, prevSearchParams, routeChanged, searchParams])

  return { route }
}

export default function Analytics({
  analyticsObject,
}: {
  analyticsObject?: Partial<AnalyticsObject>
}) {
  const fireEventOnRouteChange = useNavigation({
    on: {
      routeChanged: ({ pathname, searchParams }) => {
        console.log(
          "route changed, navigating to:",
          pathname,
          searchParams?.toString()
        )

        const finalAnalyticsObject = generateAnalyticsObject(analyticsObject)
        sendAnalyticsObject(finalAnalyticsObject)
      },
    },
  })
  return <></>
}

export function sendAnalyticsObject(analyticsObject: Partial<AnalyticsObject>) {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(analyticsObject)
  }
}
